import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { array_type, lambda_type, unit_type, list_type, int32_type, union_type, record_type, class_type, string_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { printf, toText } from "../fable_modules/fable-library.4.10.0/String.js";

export class ContactInfo extends Record {
    constructor(FullName, PhoneNumber, SendDate, Message, EmailAddress) {
        super();
        this.FullName = FullName;
        this.PhoneNumber = PhoneNumber;
        this.SendDate = SendDate;
        this.Message = Message;
        this.EmailAddress = EmailAddress;
    }
}

export function ContactInfo_$reflection() {
    return record_type("Shared.ContactInfo", [], ContactInfo, () => [["FullName", string_type], ["PhoneNumber", string_type], ["SendDate", class_type("System.DateTimeOffset")], ["Message", string_type], ["EmailAddress", string_type]]);
}

export class SaaSPackage extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Basic", "Pro", "Enterprise"];
    }
}

export function SaaSPackage_$reflection() {
    return union_type("Shared.SaaSPackage", [], SaaSPackage, () => [[], [], []]);
}

export function SaaSPackage__get_GetValue(this$) {
    switch (this$.tag) {
        case 1:
            return "Pro";
        case 2:
            return "Enterprise";
        default:
            return "Basic";
    }
}

export function SaaSPackage__get_DataCollector(this$) {
    switch (this$.tag) {
        case 1:
            return true;
        case 2:
            return true;
        default:
            return false;
    }
}

export function SaaSPackage__get_DeviceManagement(this$) {
    switch (this$.tag) {
        case 1:
            return true;
        case 2:
            return true;
        default:
            return false;
    }
}

export function SaaSPackage__get_ReportManagement(this$) {
    switch (this$.tag) {
        case 1:
            return true;
        case 2:
            return true;
        default:
            return false;
    }
}

export function SaaSPackage__get_MarketCommunication(this$) {
    switch (this$.tag) {
        case 1:
            return false;
        case 2:
            return true;
        default:
            return false;
    }
}

export function SaaSPackage__get_CustomReporting(this$) {
    switch (this$.tag) {
        case 1:
            return true;
        case 2:
            return true;
        default:
            return true;
    }
}

export function SaaSPackage__get_LoadProfileManagement(this$) {
    switch (this$.tag) {
        case 1:
            return false;
        case 2:
            return true;
        default:
            return false;
    }
}

export function SaaSPackage__get_PlusInsSqlServerAzure(this$) {
    switch (this$.tag) {
        case 1:
            return true;
        case 2:
            return true;
        default:
            return true;
    }
}

export function SaaSPackage__get_PlusInsIoT(this$) {
    switch (this$.tag) {
        case 1:
            return true;
        case 2:
            return true;
        default:
            return false;
    }
}

export class BlogContent extends Record {
    constructor(BlogId, BlogEntryName, Description, Country, Tags, Category, Author, Created, MarkdownContent) {
        super();
        this.BlogId = (BlogId | 0);
        this.BlogEntryName = BlogEntryName;
        this.Description = Description;
        this.Country = Country;
        this.Tags = Tags;
        this.Category = Category;
        this.Author = Author;
        this.Created = Created;
        this.MarkdownContent = MarkdownContent;
    }
}

export function BlogContent_$reflection() {
    return record_type("Shared.BlogContent", [], BlogContent, () => [["BlogId", int32_type], ["BlogEntryName", string_type], ["Description", string_type], ["Country", string_type], ["Tags", list_type(string_type)], ["Category", string_type], ["Author", string_type], ["Created", class_type("System.DateTime")], ["MarkdownContent", string_type]]);
}

export function Route_builder(typeName, methodName) {
    return toText(printf("/api/%s/%s"))(typeName)(methodName);
}

export class IDataBreezeApi extends Record {
    constructor(AddContactInfo) {
        super();
        this.AddContactInfo = AddContactInfo;
    }
}

export function IDataBreezeApi_$reflection() {
    return record_type("Shared.IDataBreezeApi", [], IDataBreezeApi, () => [["AddContactInfo", lambda_type(ContactInfo_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [unit_type]))]]);
}

export class IBlogApi extends Record {
    constructor(ShowBlogContent, GetBlogs) {
        super();
        this.ShowBlogContent = ShowBlogContent;
        this.GetBlogs = GetBlogs;
    }
}

export function IBlogApi_$reflection() {
    return record_type("Shared.IBlogApi", [], IBlogApi, () => [["ShowBlogContent", lambda_type(string_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [string_type]))], ["GetBlogs", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(BlogContent_$reflection())]))]]);
}

