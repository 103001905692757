import { createElement } from "react";
import { equals, createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.3.0.0/ElementBuilders.fs.js";
import { FableProps_footer, FableProps_iconTileWithText, FableProps_secondaryH3, FableProps_primaryH1, FableProps_heroMini, FableProps_faSpan, primary, FableProps_faIcon, third } from "../Props.js";
import { SaaSPackage, SaaSPackage__get_LoadProfileManagement, SaaSPackage__get_MarketCommunication, SaaSPackage__get_ReportManagement, SaaSPackage__get_DeviceManagement, SaaSPackage__get_DataCollector, SaaSPackage__get_CustomReporting, SaaSPackage__get_GetValue } from "../Shared/Shared.js";
import { singleton, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { I18n_TranslateWithFallback_E827B83 } from "../fable_modules/Fable.I18Next.0.15.2/Fable.I18Next.fs.js";
import { singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { SaaSContent, Msg, SaaSContent__get_GetValue } from "../Domain.js";
import aggregation from "../../public/png/aggregation.png";
import analyze from "../../public/png/analyze.png";
import contract from "../../public/png/contract.png";
import pi from "../../public/png/pi.png";
import { Fa_ISize, Fa_IconOption } from "../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";

export function iconCardPricing(price, icon, size, saas) {
    let elems_1, elms, elms_1, elems_3, elems_2, children_1, children_3, children_5, children_7, children_9, children_11;
    const elms_2 = ofArray([createElement("header", createObj(Helpers_combineClasses("card-header", ofArray([["style", {
        backgroundColor: third,
    }], (elems_1 = [createElement("p", createObj(Helpers_combineClasses("card-header-title", ofArray([["children", SaaSPackage__get_GetValue(saas)], ["style", {
        color: "#FFFFFF",
    }]])))), (elms = singleton(FableProps_faIcon(icon, size, primary)), createElement("span", {
        className: "card-header-icon",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), (elms_1 = singleton(createElement("div", createObj(Helpers_combineClasses("content", singleton((elems_3 = [createElement("ul", createObj(ofArray([["style", {
        listStyleType: "none",
    }], (elems_2 = [(children_1 = singleton(FableProps_faSpan(SaaSPackage__get_CustomReporting(saas), primary, I18n_TranslateWithFallback_E827B83("SaaS.CustomReporting"))), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_1)),
    })), (children_3 = singleton(FableProps_faSpan(SaaSPackage__get_DataCollector(saas), primary, "DataCollector")), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_3)),
    })), (children_5 = singleton(FableProps_faSpan(SaaSPackage__get_DeviceManagement(saas), primary, I18n_TranslateWithFallback_E827B83("SaaS.DeviceManagement"))), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_5)),
    })), (children_7 = singleton(FableProps_faSpan(SaaSPackage__get_ReportManagement(saas), primary, I18n_TranslateWithFallback_E827B83("SaaS.ReportManagement"))), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_7)),
    })), (children_9 = singleton(FableProps_faSpan(SaaSPackage__get_MarketCommunication(saas), primary, I18n_TranslateWithFallback_E827B83("SaaS.MarketCommunication"))), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_9)),
    })), (children_11 = singleton(FableProps_faSpan(SaaSPackage__get_LoadProfileManagement(saas), primary, I18n_TranslateWithFallback_E827B83("SaaS.LoadProfileManagement"))), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_11)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])))))), createElement("div", {
        className: "card-content",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))]);
    return createElement("div", {
        className: "card",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    });
}

export function saasTabWithStyling(saasContent, model, dispatch) {
    return createElement("li", createObj(Helpers_combineClasses("", toList(delay(() => append(equals(model.SaaSContent, saasContent) ? append(singleton_1(["className", "is-active"]), delay(() => singleton_1(["style", {
        backgroundColor: primary,
    }]))) : singleton_1(["style", {
        backgroundColor: "#FFFFFF",
    }]), delay(() => {
        let elems_2, elems_1, elems;
        return singleton_1((elems_2 = [createElement("a", createObj(ofArray([(elems_1 = [createElement("span", {
            children: [SaaSContent__get_GetValue(saasContent)],
        }), createElement("span", createObj(Helpers_combineClasses("icon", ofArray([["className", "is-small"], (elems = [createElement("i", {
            className: "fas fa-chevron-right",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]), ["onClick", (e) => {
            dispatch(new Msg(16, [saasContent]));
        }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]));
    })))))));
}

export function view(model, dispatch) {
    let elems_33, elems_17, elems_27, elms_8, elms_7, elms_15, elms_9, elms_10, elms_11, elms_12, elms_13, elms_14, elems_32, elms_19, elms_16, elms_17, elms_18;
    const children_22 = ofArray([FableProps_heroMini(I18n_TranslateWithFallback_E827B83("SaaSHeader")), createElement("div", createObj(Helpers_combineClasses("content", singleton((elems_33 = [createElement("section", createObj(Helpers_combineClasses("section", ofArray([["style", {
        textAlign: "center",
        backgroundColor: third,
        color: "#FFFFFF",
    }], (elems_17 = toList(delay(() => {
        let elems, children;
        return append(singleton_1(createElement("div", createObj(Helpers_combineClasses("tabs", ofArray([["className", "is-centered"], ["className", "is-toggle-rounded"], (elems = [(children = ofArray([saasTabWithStyling(new SaaSContent(0, []), model, dispatch), saasTabWithStyling(new SaaSContent(1, []), model, dispatch), saasTabWithStyling(new SaaSContent(2, []), model, dispatch), saasTabWithStyling(new SaaSContent(3, []), model, dispatch)]), createElement("ul", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))))), delay(() => {
            let elms_3, elms_2, elems_7, elems_6, elms_5, elms_4, elems_11, elems_10, elems_16, elms_6, elems_15, elems_14, elms_1, elms, elems_3, elems_2;
            const matchValue = model.SaaSContent;
            return (matchValue.tag === 1) ? singleton_1((elms_3 = ofArray([(elms_2 = singleton(createElement("img", {
                src: aggregation,
            })), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            })), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["style", {
                textAlign: "left",
            }], (elems_7 = [FableProps_primaryH1("Aggregator"), createElement("p", {
                children: I18n_TranslateWithFallback_E827B83("SaaSAggregatorText1"),
            }), FableProps_secondaryH3(I18n_TranslateWithFallback_E827B83("SaaSAggregatorText2")), createElement("ul", createObj(ofArray([["style", {
                listStyleType: "square",
            }], (elems_6 = [createElement("li", {
                children: [I18n_TranslateWithFallback_E827B83("SaaSAggregatorText3")],
            }), createElement("li", {
                children: [I18n_TranslateWithFallback_E827B83("SaaSAggregatorText4")],
            }), createElement("li", {
                children: [I18n_TranslateWithFallback_E827B83("SaaSAggregatorText5")],
            }), createElement("li", {
                children: [I18n_TranslateWithFallback_E827B83("SaaSAggregatorText6")],
            }), createElement("li", {
                children: [I18n_TranslateWithFallback_E827B83("SaaSAggregatorText7")],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))))]), createElement("div", {
                className: "columns",
                children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
            }))) : ((matchValue.tag === 2) ? singleton_1((elms_5 = ofArray([(elms_4 = singleton(createElement("img", {
                src: analyze,
            })), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
            })), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["style", {
                textAlign: "left",
            }], (elems_11 = [FableProps_primaryH1("Analyzer"), createElement("p", {
                children: I18n_TranslateWithFallback_E827B83("SaaSAnalyzerText1"),
            }), FableProps_secondaryH3(I18n_TranslateWithFallback_E827B83("SaaSAnalyzerText2")), createElement("ul", createObj(ofArray([["style", {
                listStyleType: "square",
            }], (elems_10 = [createElement("li", {
                children: [I18n_TranslateWithFallback_E827B83("SaaSAnalyzerText3")],
            }), createElement("li", {
                children: [I18n_TranslateWithFallback_E827B83("SaaSAnalyzerText4")],
            }), createElement("li", {
                children: [I18n_TranslateWithFallback_E827B83("SaaSAnalyzerText5")],
            }), createElement("li", {
                children: [I18n_TranslateWithFallback_E827B83("SaaSAnalyzerText6")],
            }), createElement("li", {
                children: [I18n_TranslateWithFallback_E827B83("SaaSAnalyzerText7")],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])]))))]), createElement("div", {
                className: "columns",
                children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
            }))) : ((matchValue.tag === 3) ? singleton_1(createElement("div", createObj(Helpers_combineClasses("columns", singleton((elems_16 = [(elms_6 = singleton(createElement("img", {
                src: contract,
            })), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
            })), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["style", {
                textAlign: "left",
            }], (elems_15 = [FableProps_primaryH1("Reporting"), createElement("p", {
                children: I18n_TranslateWithFallback_E827B83("SaaSReportingText1"),
            }), FableProps_secondaryH3(I18n_TranslateWithFallback_E827B83("SaaSReportingText2")), createElement("ul", createObj(ofArray([["style", {
                listStyleType: "square",
            }], (elems_14 = [createElement("li", {
                children: [I18n_TranslateWithFallback_E827B83("SaaSReportingText3")],
            }), createElement("li", {
                children: [I18n_TranslateWithFallback_E827B83("SaaSReportingText4")],
            }), createElement("li", {
                children: [I18n_TranslateWithFallback_E827B83("SaaSReportingText5")],
            }), createElement("li", {
                children: [I18n_TranslateWithFallback_E827B83("SaaSReportingText6")],
            }), createElement("li", {
                children: [I18n_TranslateWithFallback_E827B83("SaaSReportingText7")],
            }), createElement("li", {
                children: ["CSV"],
            }), createElement("li", {
                children: ["Excel"],
            }), createElement("li", {
                children: ["XML"],
            }), createElement("li", {
                children: [I18n_TranslateWithFallback_E827B83("SaaSReportingText8")],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])))))) : singleton_1((elms_1 = ofArray([(elms = singleton(createElement("img", {
                src: pi,
            })), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            })), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["style", {
                textAlign: "left",
            }], (elems_3 = [FableProps_primaryH1("Collector"), createElement("p", {
                children: I18n_TranslateWithFallback_E827B83("SaaSCollectorText1"),
            }), FableProps_secondaryH3(I18n_TranslateWithFallback_E827B83("SaaSCollectorText2")), createElement("ul", createObj(ofArray([["style", {
                listStyleType: "square",
            }], (elems_2 = [createElement("li", {
                children: [I18n_TranslateWithFallback_E827B83("SaaSCollectorText3")],
            }), createElement("li", {
                children: [I18n_TranslateWithFallback_E827B83("SaaSCollectorText4")],
            }), createElement("li", {
                children: ["EDIFACT MSCONS"],
            }), createElement("li", {
                children: ["Modbus"],
            }), createElement("li", {
                children: ["GPIO"],
            }), createElement("li", {
                children: ["MBus"],
            }), createElement("li", {
                children: ["S7 Client"],
            }), createElement("li", {
                children: ["MQTT"],
            }), createElement("li", {
                children: ["LoRaWAN"],
            }), createElement("li", {
                children: ["NB-IoT"],
            }), createElement("li", {
                children: [I18n_TranslateWithFallback_E827B83("SaaSCollectorText5")],
            }), createElement("li", {
                children: [I18n_TranslateWithFallback_E827B83("SaaSCollectorText6")],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))))]), createElement("div", {
                className: "columns",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            })))));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])])))), createElement("section", createObj(Helpers_combineClasses("section", ofArray([["style", {
        backgroundColor: "#FFFFFF",
        textAlign: "center",
    }], (elems_27 = [(elms_8 = singleton((elms_7 = singleton(createElement("h1", createObj(Helpers_combineClasses("title", ofArray([["className", "is-white"], ["className", "has-text-primary"], ["children", I18n_TranslateWithFallback_E827B83("Basic")], ["style", {
        textAlign: "center",
    }]]))))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
    }))), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
    })), (elms_15 = ofArray([(elms_9 = singleton(FableProps_iconTileWithText(new Fa_IconOption(11, ["fas fa-chart-line"]), new Fa_ISize(4, []), I18n_TranslateWithFallback_E827B83("DataVisualization"))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
    })), (elms_10 = singleton(FableProps_iconTileWithText(new Fa_IconOption(11, ["fas fa-tachometer-alt"]), new Fa_ISize(4, []), I18n_TranslateWithFallback_E827B83("UnlimitedMeterPoints"))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
    })), (elms_11 = singleton(FableProps_iconTileWithText(new Fa_IconOption(11, ["fas fa-infinity"]), new Fa_ISize(4, []), I18n_TranslateWithFallback_E827B83("UnlimitedStorage"))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
    })), (elms_12 = singleton(FableProps_iconTileWithText(new Fa_IconOption(11, ["fas fa-file-export"]), new Fa_ISize(4, []), I18n_TranslateWithFallback_E827B83("DataExport"))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_12)),
    })), (elms_13 = singleton(FableProps_iconTileWithText(new Fa_IconOption(11, ["fas fa-file-contract"]), new Fa_ISize(4, []), I18n_TranslateWithFallback_E827B83("RegulatoryReports"))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_13)),
    })), (elms_14 = singleton(FableProps_iconTileWithText(new Fa_IconOption(11, ["fas fa-phone"]), new Fa_ISize(4, []), "Support")), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_14)),
    }))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_15)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))])])))), createElement("section", createObj(Helpers_combineClasses("section", ofArray([["className", "has-background-white"], (elems_32 = [(elms_19 = ofArray([(elms_16 = singleton(iconCardPricing(I18n_TranslateWithFallback_E827B83("SaaSPriceBasic"), new Fa_IconOption(11, ["fas fa-rocket"]), new Fa_ISize(4, []), new SaaSPackage(0, []))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_16)),
    })), (elms_17 = singleton(iconCardPricing(I18n_TranslateWithFallback_E827B83("SaaSPricePro"), new Fa_IconOption(11, ["fas fa-bolt"]), new Fa_ISize(4, []), new SaaSPackage(1, []))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_17)),
    })), (elms_18 = singleton(iconCardPricing(I18n_TranslateWithFallback_E827B83("SaaSPriceEnterprise"), new Fa_IconOption(11, ["fas fa-hdd"]), new Fa_ISize(4, []), new SaaSPackage(2, []))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_18)),
    }))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_19)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_32))])])))), FableProps_footer(I18n_TranslateWithFallback_E827B83("FooterHeader"), I18n_TranslateWithFallback_E827B83("ContactUs"))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_33))])))))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_22)),
    });
}

