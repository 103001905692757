import { map, empty, singleton, ofArray } from "./fable_modules/fable-library.4.10.0/List.js";
import logo_cutout from "../public/png/logo_cutout.png";
import { createElement } from "react";
import { createObj } from "./fable_modules/fable-library.4.10.0/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.3.0.0/ElementBuilders.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.4.10.0/Seq.js";
import { Page, parseUrl, getHref } from "./Router.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { defaultOf } from "./fable_modules/fable-library.4.10.0/Util.js";
import { Fa_ISize, Fa_IconOption, Fa_i } from "./fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import digitalmetering_wide from "../public/png/digitalmetering_wide.png";
import { I18n_TranslateWithFallback_E827B83 } from "./fable_modules/Fable.I18Next.0.15.2/Fable.I18Next.fs.js";
import { Toastr_success, Toastr_message, Toastr_title, Toastr_position, Toastr_timeout, Toastr_hideEasing, Toastr_showCloseButton } from "./fable_modules/Elmish.Toastr.2.1.0/Elmish.Toastr.fs.js";
import { Union } from "./fable_modules/fable-library.4.10.0/Types.js";
import { union_type } from "./fable_modules/fable-library.4.10.0/Reflection.js";
import { SliderProps, slider } from "./ReactSlick.js";
import programming from "../public/png/programming.png";
import pi from "../public/png/pi.png";
import appdevelopment from "../public/png/appdevelopment.png";
import smart_metering from "../public/png/smart_metering.png";

export const primary = "#32f1c4";

export const secondary = "#1dcdfe";

export const third = "#2f455c";

export const SideMenuStyles_menuHeader = ofArray([["width", 100 + "%"], ["height", 100], ["backgroundColor", primary], ["position", "absolute"], ["top", -10], ["left", -10]]);

export const SideMenuStyles_backgroundImage = logo_cutout;

export const SideMenuStyles_menuHeaderAvatar = ofArray([["width", 138], ["height", 100], ["backgroundColor", "#000000"], ["backgroundSize", "cover"], ["backgroundImage", ("url(\'" + SideMenuStyles_backgroundImage) + "\')"], ["display", "flex"], ["justifyContent", "center"], ["alignItems", "center"], ["color", secondary], ["fontSize", 50 + "px"]]);

export const SideMenuStyles_menu = ofArray([["backgroundColor", third], ["position", "relative"]]);

export const SideMenuStyles_burgerButton = ofArray([["height", 30], ["width", 30], ["position", "fixed"], ["right", 50], ["fontSize", 10 + "px"], ["color", "#FFFFFF"], ["top", 13]]);

export const SideMenuStyles_burgerBars = ofArray([["height", 3], ["backgroundColor", "#FFFFFF"]]);

export const SideMenuStyles_crossButton = ofArray([["height", 30], ["marginRight", 10], ["top", 8], ["width", 30]]);

export const SideMenuStyles_cross = ofArray([["height", 30], ["backgroundColor", "#FFFFFF"]]);

export const SideMenuStyles_itemList = ofArray([["marginTop", 50], ["display", "flex"], ["flexDirection", "column"], ["justifyContent", "center"], ["height", 80 + "%"]]);

export const SideMenuStyles_item = ofArray([["margin", ((((((10 + "px ") + 10) + "px ") + 0) + "px ") + 10) + "px"], ["display", "flex"], ["color", "#FFFFFF"], ["padding", 10], ["display", "flex"], ["alignItems", "center"], ["justifyContent", "flex-start"]]);

export const SideMenuStyles_menuWrap = ofArray([["backgroundColor", "#FFFFFF"], ["position", "fixed"]]);

export const SideMenuStyles_morphShape = ofArray([["backgroundColor", "#FFFFFF"], ["color", "#FFFFFF"]]);

export const SideMenuStyles_overlay = singleton(["backgroundColor", "#000000bf"]);

export const SideMenuStyles_logout = ofArray([["bottom", 0], ["position", "absolute"], ["padding", 0], ["margin", 0], ["height", 100], ["width", 100 + "%"], ["backgroundColor", primary], ["display", "flex"], ["justifyContent", "center"], ["alignItems", "center"], ["fontWeight", "bolder"], ["color", secondary]]);

export function FableProps_whiteP(txt) {
    return createElement("p", {
        style: {
            color: "#FFFFFF",
        },
        children: txt,
    });
}

export function FableProps_whiteH1(txt) {
    return createElement("h2", {
        style: {
            color: "#FFFFFF",
        },
        children: txt,
    });
}

export function FableProps_whiteH2(txt) {
    return createElement("h2", {
        style: {
            color: "#FFFFFF",
        },
        children: txt,
    });
}

export function FableProps_whiteH3(txt) {
    return createElement("h3", {
        style: {
            color: "#FFFFFF",
        },
        children: txt,
    });
}

export function FableProps_primaryH1(txt) {
    return createElement("h1", {
        style: {
            color: primary,
        },
        children: txt,
    });
}

export function FableProps_primaryH2(txt) {
    return createElement("h2", {
        style: {
            color: primary,
        },
        children: txt,
    });
}

export function FableProps_secondaryH3(txt) {
    return createElement("h3", {
        style: {
            color: secondary,
        },
        children: txt,
    });
}

export function FableProps_whiteHeaderBig(txt) {
    return createElement("header", {
        className: "is-hidden-mobile",
        style: {
            fontSize: 40 + "px",
            textAlign: "center",
            color: "#FFFFFF",
        },
        children: txt,
    });
}

export function FableProps_breadcrumb(t, t2, p) {
    let elems_1, elems;
    return createElement("nav", createObj(Helpers_combineClasses("breadcrumb", ofArray([["className", "has-succeeds-separator"], ["className", "is-large"], ["style", {
        backgroundColor: primary,
    }], (elems_1 = [createElement("ul", createObj(ofArray([["style", {
        listStyleType: "none",
        marginTop: 0,
        marginBottom: -25,
    }], (elems = toList(delay(() => {
        let children;
        return append(singleton_1((children = singleton(createElement("a", {
            children: t,
            className: "is-active",
            style: {
                color: third,
            },
            href: getHref(parseUrl(singleton(t))),
        })), createElement("li", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))), delay(() => {
            let children_2;
            const matchValue = t2;
            return (matchValue != null) ? singleton_1((children_2 = singleton(createElement("a", {
                children: matchValue,
                className: "is-active",
                style: {
                    color: third,
                },
                href: getHref(p),
            })), createElement("li", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            }))) : singleton_1(defaultOf());
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))));
}

export function FableProps_faLink(icon, size, text, p) {
    let elems;
    return createElement("a", createObj(ofArray([["href", p], (elems = [Fa_i(ofArray([icon, new Fa_IconOption(0, [size]), new Fa_IconOption(14, [singleton(["style", {
        color: secondary,
    }])])]), []), createElement("p", {
        style: {
            color: "#FFFFFF",
        },
        children: text,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function FableProps_faLinkNoText(icon, size, p) {
    let elems;
    return createElement("a", createObj(ofArray([["href", p], (elems = [Fa_i(ofArray([icon, new Fa_IconOption(0, [size]), new Fa_IconOption(14, [singleton(["style", {
        color: secondary,
    }])])]), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function FableProps_heroMini(txt) {
    let elems_1;
    const headerMobile = createElement("h1", {
        className: "is-hidden-desktop",
        style: {
            fontSize: 30 + "px",
            color: "#FFFFFF",
        },
        children: txt,
    });
    const headerDesktop = createElement("h1", {
        className: "is-hidden-mobile",
        style: {
            fontSize: 50 + "px",
            color: "#FFFFFF",
        },
        children: txt,
    });
    return createElement("section", createObj(Helpers_combineClasses("hero", ofArray([["style", {
        height: 200,
        backgroundSize: "cover",
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),url(${digitalmetering_wide})`,
    }], (elems_1 = [createElement("div", createObj(Helpers_combineClasses("hero-head", empty()))), createElement("div", createObj(Helpers_combineClasses("hero-body", singleton(["children", Interop_reactApi.Children.toArray([headerMobile, headerDesktop])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))));
}

export function FableProps_contractBox() {
    const elms = ofArray([createElement("h2", {
        style: {
            color: secondary,
        },
        children: I18n_TranslateWithFallback_E827B83("Contract.Address"),
    }), createElement("h3", {
        children: I18n_TranslateWithFallback_E827B83("CompanyName"),
    }), createElement("h3", {
        children: I18n_TranslateWithFallback_E827B83("CompanyStreet"),
    }), createElement("h3", {
        children: I18n_TranslateWithFallback_E827B83("CompanyCityAndPostalCode"),
    }), createElement("h3", {
        children: I18n_TranslateWithFallback_E827B83("CompanyCountry"),
    }), createElement("h3", {
        children: I18n_TranslateWithFallback_E827B83("ContactCEO"),
    })]);
    return createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function FableProps_buttonWithLink(txt, page) {
    return createElement("a", createObj(Helpers_combineClasses("button", ofArray([["children", txt], ["style", {
        backgroundColor: primary,
    }], ["href", getHref(page)]]))));
}

export function FableProps_buttonWithLinkCentered(txt, page) {
    return createElement("a", createObj(Helpers_combineClasses("button", ofArray([["children", txt], ["style", {
        backgroundColor: third,
        color: "#FFFFFF",
        textAlign: "center",
    }], ["href", getHref(page)]]))));
}

export function FableProps_buttonWithLinkCenteredPrimary(txt, page) {
    return createElement("a", createObj(Helpers_combineClasses("button", ofArray([["children", txt], ["style", {
        backgroundColor: primary,
        color: third,
        textAlign: "center",
    }], ["href", getHref(page)]]))));
}

export function FableProps_footer(txt, contactUs) {
    let elms_2, elms, elms_1, elms_5, elms_3, elms_4, elems_12, elems_11, elms_10, elms_7, elms_9;
    const headerMobile = createElement("h1", {
        className: "is-hidden-desktop",
        style: {
            color: "#FFFFFF",
        },
        children: txt,
    });
    const headerDesktop = createElement("h1", {
        className: "is-hidden-mobile",
        style: {
            color: "#FFFFFF",
        },
        children: txt,
    });
    let socialMedia;
    const elms_6 = ofArray([createElement("h3", {
        style: {
            color: "#FFFFFF",
        },
        children: I18n_TranslateWithFallback_E827B83("FollowUs"),
    }), (elms_2 = ofArray([(elms = singleton(FableProps_faLinkNoText(new Fa_IconOption(11, ["fab fa-twitter"]), new Fa_ISize(3, []), "https://twitter.com/DataBreeze")), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (elms_1 = singleton(FableProps_faLinkNoText(new Fa_IconOption(11, ["fab fa-linkedin"]), new Fa_ISize(3, []), "https://www.linkedin.com/company/databreeze")), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    })), (elms_5 = ofArray([(elms_3 = singleton(FableProps_faLinkNoText(new Fa_IconOption(11, ["fab fa-facebook"]), new Fa_ISize(3, []), "https://www.facebook.com/DataBreezeInc")), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    })), (elms_4 = singleton(FableProps_faLinkNoText(new Fa_IconOption(11, ["fab fa-github"]), new Fa_ISize(3, []), "https://github.com/orgs/DataBreezeUG/")), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    }))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    }))]);
    socialMedia = createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
    });
    return createElement("section", createObj(Helpers_combineClasses("hero", ofArray([["style", {
        backgroundSize: "cover",
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),url(${digitalmetering_wide})`,
    }], (elems_12 = [createElement("div", createObj(Helpers_combineClasses("hero-head", empty()))), createElement("div", createObj(Helpers_combineClasses("hero-body", singleton((elems_11 = [(elms_10 = ofArray([(elms_7 = ofArray([headerMobile, headerDesktop, FableProps_buttonWithLink(contactUs, new Page(6, []))]), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray([socialMedia]),
    }), (elms_9 = ofArray([createElement("h1", {
        style: {
            color: "#FFFFFF",
        },
        children: I18n_TranslateWithFallback_E827B83("Imprint"),
    }), FableProps_primaryH2(I18n_TranslateWithFallback_E827B83("CompanyName")), FableProps_whiteP(I18n_TranslateWithFallback_E827B83("CompanyStreet")), FableProps_whiteP(I18n_TranslateWithFallback_E827B83("CompanyCityAndPostalCode")), FableProps_whiteP(I18n_TranslateWithFallback_E827B83("CompanyCountry"))]), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
    }))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])]))));
}

export function FableProps_faIcon(icon, size, color) {
    return Fa_i(ofArray([icon, new Fa_IconOption(0, [size]), new Fa_IconOption(14, [singleton(["style", {
        color: color,
    }])])]), []);
}

export function FableProps_faSpan(isValid, color, text) {
    let elms;
    const children_1 = ofArray([(elms = singleton(Fa_i(toList(delay(() => append(isValid ? singleton_1(new Fa_IconOption(11, ["fas fa-check"])) : singleton_1(new Fa_IconOption(11, ["fas fa-times"])), delay(() => singleton_1(new Fa_IconOption(14, [singleton(["style", {
        color: color,
    }])])))))), [])), createElement("span", {
        className: "icon",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), createElement("span", {
        style: {
            fontSize: 20 + "px",
        },
        children: text,
    })]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_1)),
    });
}

export function FableProps_faSpanBullet(color, text) {
    let elms;
    const children_1 = ofArray([(elms = singleton(Fa_i(ofArray([new Fa_IconOption(11, ["fas fa-chevron-right"]), new Fa_IconOption(14, [singleton(["style", {
        color: color,
    }])])]), [])), createElement("span", {
        className: "icon",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), createElement("span", {
        children: text,
    })]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_1)),
    });
}

export function FableProps_faSpanBulletLeft(color, text) {
    let elems_1, elms;
    return createElement("div", createObj(ofArray([["style", {
        textAlign: "left",
    }], (elems_1 = [(elms = singleton(Fa_i(ofArray([new Fa_IconOption(11, ["fas fa-chevron-right"]), new Fa_IconOption(14, [singleton(["style", {
        color: color,
    }])])]), [])), createElement("span", {
        className: "icon",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), createElement("span", {
        children: text,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function FableProps_iconTileWithText(icon, size, txt) {
    let elems;
    return createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-child"], (elems = [FableProps_faIcon(icon, size, primary), createElement("h4", {
        children: [txt],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
}

export function FableProps_iconTileWithTextDarkBackground(icon, size, txt) {
    let elems;
    return createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-child"], (elems = [FableProps_faIcon(icon, size, primary), createElement("h4", {
        style: {
            color: "#FFFFFF",
        },
        children: txt,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
}

export function FableProps_h2WithIcon(icon, text, withColor) {
    let elms;
    const children_1 = ofArray([(elms = singleton(Fa_i(ofArray([icon, new Fa_IconOption(0, [new Fa_ISize(2, [])]), new Fa_IconOption(14, [singleton(["style", {
        color: secondary,
    }])])]), [])), createElement("span", {
        className: "icon",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), createElement("h2", {
        style: createObj(toList(delay(() => append(singleton_1(["display", "inline"]), delay(() => (withColor ? singleton_1(["color", primary]) : singleton_1(["color", third]))))))),
        children: " " + text,
    })]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_1)),
    });
}

export function FableProps_pWithIconWhite(icon, text) {
    let elms;
    const children_1 = ofArray([(elms = singleton(Fa_i(ofArray([icon, new Fa_IconOption(0, [new Fa_ISize(2, [])]), new Fa_IconOption(14, [singleton(["style", {
        color: secondary,
    }])])]), [])), createElement("span", {
        className: "icon",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), createElement("p", {
        style: {
            display: "inline",
            color: "#FFFFFF",
        },
        children: text,
    })]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_1)),
    });
}

export function FableProps_tiles(icon, header, txt, withColor, withLink) {
    let elems_2, elems, elems_1;
    return createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-child"], (elems_2 = [createElement("div", createObj(ofArray([["className", "is-hidden-desktop"], ["style", {
        textAlign: "center",
    }], (elems = toList(delay(() => append(singleton_1(FableProps_h2WithIcon(icon, header, withColor)), delay(() => {
        const matchValue = withLink;
        if (matchValue == null) {
            return singleton_1(createElement("p", {
                children: [txt],
            }));
        }
        else {
            const link = matchValue;
            return singleton_1(createElement("a", {
                style: {
                    color: secondary,
                },
                href: link,
                children: txt,
            }));
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", "is-hidden-mobile"], (elems_1 = toList(delay(() => append(singleton_1(FableProps_h2WithIcon(icon, header, withColor)), delay(() => {
        const matchValue_1 = withLink;
        if (matchValue_1 == null) {
            return singleton_1(createElement("p", {
                children: [txt],
            }));
        }
        else {
            const link_1 = matchValue_1;
            return singleton_1(createElement("a", {
                style: {
                    color: secondary,
                },
                href: link_1,
                children: txt,
            }));
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))));
}

export function Toaster_toast(title, message) {
    return Toastr_showCloseButton(Toastr_hideEasing("swing", Toastr_timeout(3000, Toastr_position("toast-top-right", Toastr_title(title, Toastr_message(message))))));
}

export function Toaster_successToast(title, message) {
    return Toastr_success(Toaster_toast(title, message));
}

export function Toaster_toastLanguage() {
    return Toaster_successToast(I18n_TranslateWithFallback_E827B83("Language"), I18n_TranslateWithFallback_E827B83("LanguageChanged"));
}

export function Toaster_toastMessageSent() {
    return Toaster_successToast(I18n_TranslateWithFallback_E827B83("Message"), I18n_TranslateWithFallback_E827B83("MessageSent"));
}

export class Slick_Partner extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Ts", "Msu", "CompositionalIT"];
    }
}

export function Slick_Partner_$reflection() {
    return union_type("Props.Slick.Partner", [], Slick_Partner, () => [[], [], []]);
}

export function Slick_Partner__get_GetValue(this$) {
    switch (this$.tag) {
        case 1:
            return "msu solutions GmbH";
        case 2:
            return "Compositional IT";
        default:
            return "TS Umweltanlagenbau GmbH";
    }
}

export const Slick_partner = ofArray([new Slick_Partner(1, []), new Slick_Partner(2, []), new Slick_Partner(0, [])]);

export function Slick_partners(text, imgSrc) {
    let elems;
    const elms = ofArray([createElement("h2", {
        style: {
            textAlign: "center",
            color: "#FFFFFF",
        },
        children: text,
    }), createElement("figure", createObj(Helpers_combineClasses("image", singleton((elems = [createElement("img", {
        style: {
            width: 400,
            backgroundColor: "#FFFFFF",
        },
        src: imgSrc,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])))))]);
    return createElement("div", {
        className: "content",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function Slick_partnerSlider(tsScr, msuScr, compositionalIT) {
    return slider(ofArray([new SliderProps(0, [false]), new SliderProps(1, [false]), new SliderProps(3, [true])]), map((p) => {
        const imgSrc = (p.tag === 1) ? msuScr : ((p.tag === 2) ? compositionalIT : tsScr);
        return Slick_partners(Slick_Partner__get_GetValue(p), imgSrc);
    }, Slick_partner));
}

export const Slick_pages = ofArray([new Page(10, []), new Page(8, []), new Page(9, []), new Page(7, [])]);

export function Slick_heroArea(imgSrc, h2Text, h3Text, readMore, page) {
    let elems_6, elems_5, elems_4, elems_1, elems, elems_3, elems_2;
    return createElement("section", createObj(Helpers_combineClasses("hero", singleton((elems_6 = [createElement("div", createObj(Helpers_combineClasses("hero-head", empty()))), createElement("div", createObj(Helpers_combineClasses("hero-body", singleton((elems_5 = [createElement("div", createObj(Helpers_combineClasses("columns", singleton((elems_4 = [createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-7"], (elems_1 = [createElement("figure", createObj(Helpers_combineClasses("image", singleton((elems = [createElement("img", {
        style: createObj(toList(delay(() => ((imgSrc.indexOf("appdevelopment") >= 0) ? append(singleton_1(["width", 270]), delay(() => singleton_1(["height", 500]))) : empty_1())))),
        src: imgSrc,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-5"], ["style", {
        color: primary,
        textAlign: "center",
    }], (elems_3 = [FableProps_primaryH2(h2Text), createElement("div", createObj(Helpers_combineClasses("box", ofArray([["style", {
        backgroundColor: third,
    }], (elems_2 = [FableProps_whiteP(h3Text), FableProps_buttonWithLinkCenteredPrimary(readMore, page)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])))));
}

export function Slick_areaSlider(areaElements) {
    let elems;
    return createElement("section", createObj(Helpers_combineClasses("section", ofArray([["style", {
        backgroundColor: third,
    }], (elems = [slider(ofArray([new SliderProps(2, [true]), new SliderProps(0, [false]), new SliderProps(1, [true]), new SliderProps(3, [true]), new SliderProps(5, [true]), new SliderProps(6, [7000]), new SliderProps(7, [1000]), new SliderProps(9, [1]), new SliderProps(10, [1])]), map((p) => {
        let patternInput;
        switch (p.tag) {
            case 8: {
                patternInput = [programming, areaElements.Consultancy.Text, areaElements.Consultancy.SubText];
                break;
            }
            case 9: {
                patternInput = [pi, areaElements.EnergyDataConsultancy.Text, areaElements.EnergyDataConsultancy.SubText];
                break;
            }
            case 11: {
                patternInput = [pi, areaElements.EnergyEngineeringServices.Text, areaElements.EnergyEngineeringServices.SubText];
                break;
            }
            case 7: {
                patternInput = [appdevelopment, areaElements.AppDevelopment.Text, areaElements.AppDevelopment.SubText];
                break;
            }
            case 13: {
                patternInput = [appdevelopment, areaElements.AppDevelopment.Text, areaElements.AppDevelopment.SubText];
                break;
            }
            case 0:
            case 1:
            case 3:
            case 4:
            case 5:
            case 6:
            case 2:
            case 12: {
                throw new Error("Not Implemented");
                break;
            }
            default:
                patternInput = [smart_metering, areaElements.DigitalMetering.Text, areaElements.DigitalMetering.SubText];
        }
        return Slick_heroArea(patternInput[0], patternInput[1], patternInput[2], areaElements.ReadMoreText, p);
    }, Slick_pages))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
}

