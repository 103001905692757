import { Program_withReactBatched } from "./fable_modules/Fable.Elmish.React.4.0.0/react.fs.js";
import { ProgramModule_run, ProgramModule_mkProgram } from "./fable_modules/Fable.Elmish.4.1.0/program.fs.js";
import { update, init } from "./State.js";
import { view } from "./View.js";
import * as Client_DE from "../Translations/de/Client_DE.json";
import * as Client_EN from "../Translations/en/Client_EN.json";
import * as Client_FR from "../Translations/fr/Client_FR.json";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { I18n_Init_Z6861C5C0 } from "./fable_modules/Fable.I18Next.0.15.2/Fable.I18Next.fs.js";

export const program = Program_withReactBatched("elmish-app", ProgramModule_mkProgram(init, update, view));

export const initLanguage = (() => {
    const matchValue = window.location.hostname;
    return (matchValue === "databreeze.de") ? "de" : ((matchValue === "databreeze.ca") ? "en" : "en");
})();

export const resourceDE = Client_DE;

export const resourceEN = Client_EN;

export const resourceFR = Client_FR;

(function () {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (I18n_Init_Z6861C5C0({
        de: resourceDE,
        en: resourceEN,
        fr: resourceFR,
    }, initLanguage).then(() => {
        ProgramModule_run(program);
        return Promise.resolve();
    }))));
    void pr;
})();

