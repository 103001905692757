import { Union } from "./fable_modules/fable-library.4.10.0/Types.js";
import { union_type, int32_type } from "./fable_modules/fable-library.4.10.0/Reflection.js";
import { ofArray, singleton, tail, head, isEmpty } from "./fable_modules/fable-library.4.10.0/List.js";
import { Route_$007CInt$007C_$007C } from "./fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { RouterModule_encodeParts } from "./fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { int32ToString } from "./fable_modules/fable-library.4.10.0/Util.js";

export class Page extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Home", "About", "Imprint", "Blogs", "Blog", "SaaS", "Contact", "AppDevelopment", "Consultancy", "EnergyDataConsultancy", "DigitalMeteringConsultancy", "EnergyEngineeringServices", "ProjectMethodology", "ElectroMobility"];
    }
}

export function Page_$reflection() {
    return union_type("Router.Page", [], Page, () => [[], [], [], [], [["Item", int32_type]], [], [], [], [], [], [], [], [], []]);
}

export function Page__get_GetValue(this$) {
    switch (this$.tag) {
        case 1:
            return "About";
        case 2:
            return "Imprint";
        case 3:
            return "Blogs";
        case 4:
            return "Blog";
        case 5:
            return "SaaS";
        case 6:
            return "Contact";
        case 7:
            return "AppDevelopment";
        case 8:
            return "Consultancy";
        case 9:
            return "EnergyDataConsultancy";
        case 10:
            return "DigitalMeteringConsultancy";
        case 11:
            return "EnergyEngineeringServices";
        case 12:
            return "Project Methodology";
        case 13:
            return "ElectroMobility";
        default:
            return "Home";
    }
}

export const defaultPage = new Page(0, []);

export function parseUrl(_arg) {
    let matchResult, blogId;
    if (!isEmpty(_arg)) {
        switch (head(_arg)) {
            case "": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 0;
                }
                else {
                    matchResult = 5;
                }
                break;
            }
            case "about": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 1;
                }
                else {
                    matchResult = 5;
                }
                break;
            }
            case "blogs": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 2;
                }
                else {
                    matchResult = 5;
                }
                break;
            }
            case "Blogs": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 3;
                }
                else {
                    matchResult = 5;
                }
                break;
            }
            case "blog": {
                if (!isEmpty(tail(_arg))) {
                    const activePatternResult = Route_$007CInt$007C_$007C(head(tail(_arg)));
                    if (activePatternResult != null) {
                        if (isEmpty(tail(tail(_arg)))) {
                            matchResult = 4;
                            blogId = activePatternResult;
                        }
                        else {
                            matchResult = 5;
                        }
                    }
                    else {
                        matchResult = 5;
                    }
                }
                else {
                    matchResult = 5;
                }
                break;
            }
            default:
                matchResult = 5;
        }
    }
    else {
        matchResult = 5;
    }
    switch (matchResult) {
        case 0:
            return new Page(0, []);
        case 1:
            return new Page(1, []);
        case 2:
            return new Page(3, []);
        case 3:
            return new Page(3, []);
        case 4:
            return new Page(4, [blogId]);
        default: {
            let matchResult_1;
            if (!isEmpty(_arg)) {
                switch (head(_arg)) {
                    case "saas": {
                        if (isEmpty(tail(_arg))) {
                            matchResult_1 = 0;
                        }
                        else {
                            matchResult_1 = 9;
                        }
                        break;
                    }
                    case "contact": {
                        if (isEmpty(tail(_arg))) {
                            matchResult_1 = 1;
                        }
                        else {
                            matchResult_1 = 9;
                        }
                        break;
                    }
                    case "appdevelopment": {
                        if (isEmpty(tail(_arg))) {
                            matchResult_1 = 2;
                        }
                        else {
                            matchResult_1 = 9;
                        }
                        break;
                    }
                    case "consultancy": {
                        if (isEmpty(tail(_arg))) {
                            matchResult_1 = 3;
                        }
                        else {
                            matchResult_1 = 9;
                        }
                        break;
                    }
                    case "energydataconsultancy": {
                        if (isEmpty(tail(_arg))) {
                            matchResult_1 = 4;
                        }
                        else {
                            matchResult_1 = 9;
                        }
                        break;
                    }
                    case "digitalmeteringconsultancy": {
                        if (isEmpty(tail(_arg))) {
                            matchResult_1 = 5;
                        }
                        else {
                            matchResult_1 = 9;
                        }
                        break;
                    }
                    case "energyengineeringservices": {
                        if (isEmpty(tail(_arg))) {
                            matchResult_1 = 6;
                        }
                        else {
                            matchResult_1 = 9;
                        }
                        break;
                    }
                    case "electromobility": {
                        if (isEmpty(tail(_arg))) {
                            matchResult_1 = 7;
                        }
                        else {
                            matchResult_1 = 9;
                        }
                        break;
                    }
                    case "projectmethodology": {
                        if (isEmpty(tail(_arg))) {
                            matchResult_1 = 8;
                        }
                        else {
                            matchResult_1 = 9;
                        }
                        break;
                    }
                    default:
                        matchResult_1 = 9;
                }
            }
            else {
                matchResult_1 = 9;
            }
            switch (matchResult_1) {
                case 0:
                    return new Page(5, []);
                case 1:
                    return new Page(6, []);
                case 2:
                    return new Page(7, []);
                case 3:
                    return new Page(8, []);
                case 4:
                    return new Page(9, []);
                case 5:
                    return new Page(10, []);
                case 6:
                    return new Page(11, []);
                case 7:
                    return new Page(13, []);
                case 8:
                    return new Page(12, []);
                default:
                    return defaultPage;
            }
        }
    }
}

export function getHref(_arg) {
    switch (_arg.tag) {
        case 1:
            return RouterModule_encodeParts(singleton("about"), 1);
        case 2:
            return RouterModule_encodeParts(singleton("imprint"), 1);
        case 3:
            return RouterModule_encodeParts(singleton("blogs"), 1);
        case 4:
            return RouterModule_encodeParts(ofArray(["blog", int32ToString(_arg.fields[0])]), 1);
        case 5:
            return RouterModule_encodeParts(singleton("saas"), 1);
        case 6:
            return RouterModule_encodeParts(singleton("contact"), 1);
        case 7:
            return RouterModule_encodeParts(singleton("appdevelopment"), 1);
        case 8:
            return RouterModule_encodeParts(singleton("consultancy"), 1);
        case 9:
            return RouterModule_encodeParts(singleton("energydataconsultancy"), 1);
        case 10:
            return RouterModule_encodeParts(singleton("digitalmeteringconsultancy"), 1);
        case 11:
            return RouterModule_encodeParts(singleton("energyengineeringservices"), 1);
        case 12:
            return RouterModule_encodeParts(singleton("projectmethodology"), 1);
        case 13:
            return RouterModule_encodeParts(singleton("electromobility"), 1);
        default:
            return RouterModule_encodeParts(singleton(""), 1);
    }
}

