import { class_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { createElement } from "react";
import React from "react";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.2.7.0/React.fs.js";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { map, empty, singleton, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { FableProps_heroMini, primary } from "../Props.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import RenewableEnergySnow from "../../public/png/blog/RenewableEnergySnow.png";
import RenewableEnergyGermany from "../../public/png/blog/RenewableEnergyGermany.png";
import EnergyPlant from "../../public/png/blog/EnergyPlant.png";
import DataAct from "../../public/png/blog/DataAct.png";
import tf_image from "../../public/png/tf_image.png";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.3.0.0/ElementBuilders.fs.js";
import { Msg } from "../Domain.js";
import { ofArray, singleton as singleton_1 } from "../fable_modules/fable-library.4.10.0/List.js";
import { compare, toLongDateString } from "../fable_modules/fable-library.4.10.0/Date.js";
import { sortByDescending, chunkBySize } from "../fable_modules/fable-library.4.10.0/Array.js";
import { I18n_TranslateWithFallback_E827B83 } from "../fable_modules/Fable.I18Next.0.15.2/Fable.I18Next.fs.js";

export class Highlight {
    constructor() {
    }
}

export function Highlight_$reflection() {
    return class_type("Views.Blogs.Highlight", void 0, Highlight);
}

export function AnimationsOnHover(animationsOnHoverInputProps) {
    const content = animationsOnHoverInputProps.content;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setHovered = patternInput[1];
    return createElement("div", {
        style: createObj(toList(delay(() => append(singleton(["transitionDuration", 25 + "ms"]), delay(() => append(singleton(["transitionProperty", join(",", ["background-color", "color"])]), delay(() => (patternInput[0] ? append(singleton(["backgroundColor", primary]), delay(() => singleton(["color", "#000000"]))) : empty())))))))),
        onMouseEnter: (_arg) => {
            setHovered(true);
        },
        onMouseLeave: (_arg_1) => {
            setHovered(false);
        },
        children: Interop_reactApi.Children.toArray(Array.from(content)),
    });
}

export function blogTile(blogContent, dispatch) {
    let elems_1, elems;
    let imageSrc;
    const matchValue = blogContent.BlogId | 0;
    imageSrc = ((matchValue === 1) ? RenewableEnergySnow : ((matchValue === 2) ? RenewableEnergyGermany : ((matchValue === 3) ? RenewableEnergyGermany : ((matchValue === 4) ? EnergyPlant : ((matchValue === 5) ? DataAct : tf_image)))));
    return createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-child"], ["className", "is-4"], ["onClick", (_arg) => {
        dispatch(new Msg(18, [blogContent]));
    }], (elems_1 = [createElement(AnimationsOnHover, {
        content: ofArray([createElement("figure", createObj(Helpers_combineClasses("image", singleton_1((elems = [createElement("img", {
            src: imageSrc,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))))), createElement("p", {
            children: [toLongDateString(blogContent.Created)],
        }), createElement("h2", {
            children: [blogContent.BlogEntryName],
        })]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))));
}

export function blogTiles(blogContents, dispatch) {
    const chunkBlogs = chunkBySize(3, sortByDescending((x) => x.Created, blogContents, {
        Compare: compare,
    }));
    const elms = toList(delay(() => map((chunk) => {
        let elems;
        return createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-parent"], (elems = toList(delay(() => map((blog) => blogTile(blog, dispatch), chunk))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
    }, chunkBlogs)));
    return createElement("section", {
        className: "section",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function view(model, dispatch) {
    let elems;
    const children = ofArray([FableProps_heroMini(I18n_TranslateWithFallback_E827B83("Blog")), createElement("div", createObj(Helpers_combineClasses("content", ofArray([["style", {
        overflow: "auto",
    }], (elems = [blogTiles(model.BlogContents, dispatch)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

