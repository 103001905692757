{
    "translation": {
        "About": "Über uns",
        "AboutText": "Wir ein junges IT-Consulting Unternehmen. Wir helfen Ihnen bei der Entwicklung und Betreuung Ihrer Webseite. Wir bieten Ihnen eine Vielzahl von Produkten und Diensten, die Ihre Webseite verwirklichen. Wir bieten auch eine Vielzahl an Komponenten, die Ihnen bei der Entwicklung Ihrer Webseite helfen können.",
        "Contract" : {
            "Address": "Adresse"
        },
        "AppDevelopment": "App Entwicklung",
        "AppDevelopmentSubText": "Wir entwickeln für Sie plattformübergreifende React Native Expo Apps",
        "AppDevelopmentHeader": "Wir entwickeln Apps für Sie",
        "AppDevelopmentReactNative": "Plattformübergreifende App-Entwicklung mit React Native und Expo",
        "AppDevelopmentReactNativeText": "Wir entwickeln Apps für Android und iOS mit React Native und Expo",
        "AppDevelopmentText1": "Modernste App Entwicklung",
        "AppDevelopmentText2": "Internationalisierung mit i18n",
        "AppDevelopmentText3": "Interaktive Diagramme mit React Native Skia",
        "AppDevelopmentText4": "White Label Apps",
        "AppDevelopmentText5": "Hochleistungsfähige mobile App-Entwicklung für iOS und Android",
        "Azure": "Wir sind erfahrene Azure Cloud Solutions Architects.",
        "AzureHeader": "Azure Cloud-Lösungen.",
        "Basic": "Die Basics sind inklusive",
        "Blog": "Neueste Updates, Nachrichten und Ansichten",
        "BookConsultation": "Jetzt Beratungstermin vereinbaren",
        "BusinessAreas": "Geschäftsbereiche",
        "CEO": "Leitender Software Berater / Gründer",
        "CollectorText": "Nutzen Sie unseren Collector um Ihre Standorte zu digitalisieren",
        "CompanyCityAndPostalCode": "K1S 5A2, Ottawa, ON",
        "CompanyCountry": "Kanada",
        "CompanyName": "DataBreeze Inc.",
        "CompanyStreet": "30 Marco Lane",
        "CompetenceDigitalMetering": "Kompetenz im digitalen Zählerwesen",
        "CompetenceDigitalMeteringText": "Wir unterstützen Sie gerne beim Erfassen von Digitalen Zählerwerten. Im Bereich IoT kennen wir uns aus!",
        "Consultancy": "IT-Beratung",
        "ConsultancySubText": "Wir helfen Ihnen, nachhaltige und moderne IT-Lösungen zu implementieren. Wir implementieren Datenvalidierungsprozesse, um sicherzustellen, dass Ihre Datenqualität den regulatorischen Anforderungen entspricht. Wir haben Erfahrung in der Entwicklung maßgeschneiderter Meldesysteme, die wiederkehrende Meldungen an offizielle Institute beenden.",
        "ConsultingHeader": "Wir bieten folgende IT-Beratungen an",
        "Contact": "Kontakt",
        "ContactCEO": "Geschäftsführer: Tim Forkmann",
        "ContactEmail": "E-Mail Adresse",
        "ContactEmailMessage": "Oder schreiben Sie uns",
        "ContactFullName": "Name",
        "ContactPhone": "So können Sie uns erreichen",
        "ContactText": "Was können wir für Sie tun?",
        "ContactUs": "Kontaktieren Sie uns",
        "ContinuousImprovement": "Kontinuierliche Verbesserung und Wartung",
        "ContinuousImprovement1": "Systemüberwachung",
        "ContinuousImprovement2": "Software-Updates",
        "ContinuousImprovement3": "Sicherheitspatches",
        "ContinuousImprovement4": "Fehlerbehebungen",
        "ContinuousImprovement5": "Leistungsverbesserungen",
        "ContinuousImprovement6": "Systemtests zur Sicherstellung kritischer Datenintegrität",
        "CorePhilosophy": "Kernphilosophie",
        "Correctness": "Richtigkeit",
        "CostEffectiveSolutions": "Kostenoptimierte Lösungen",
        "Creativity": "Kreativität",
        "CrossPlatform": "Cross-Platform",
        "CustomerFocused": "Kundenorientiert",
        "CustomizedFirmware": "Kundenspezifische Firmwareentwicklung",
        "CustomizedFirmwareText": "Wir können die Device Firmware spezial für Ihre Bedürfnisse anpassen",
        "CustomizedRegulatoryReports": "Kundenspezifische Datenberichte zur Einhaltung gesetzlicher Vorschriften",
        "CustomizedRegulatoryReportsText": "Wir helfen Ihnen, Ihre Datenberichte zur Einhaltung gesetzlicher Vorschriften zu erstellen",
        "DataAnalysis": "Datenanalysen & Datenkorrektur",
        "DataAnalysisText": "Wir führen für Sie gerne Datenanalysen durch und können auch Ihre Daten kontrollieren und ggf. korrigieren.",
        "DataExport": "Datenexport und Schnittstellen",
        "DataPipelines": "Aufsetzen von Datenpipelines",
        "DataPipelinesText": "Wir optimieren Ihre Datenströme und implementiere moderne Datenpipelines für Sie.",
        "DataProtocol": "Vielzahl an Datenübertragungsprotokolle",
        "DataProtocolText": "Modbus, GPIO, S7, MBus",
        "DataQuality": "Datenqualitäts - und Prozessoptimierungen",
        "DataQualityText": "Vor allem in existierende IT-Infrastrukturen finden sich große Fehler und Datenlücken, welche zu Problemen bei der korrekten Erstellung von Berichten führen. Wir implementieren für Sie Datenvalidierungs- und Ersatzwertbildungsprozesse um jederzeit plausible Berichte erstellen zu können.",
        "DataSolutions": "Datenlösungen",
        "DataSolutionsText": "Wir helfen Ihnen bei Ihren Problemen",
        "DataVisualization": "Datenvisualisierung & -analyse",
        "DeviceManagement": "Zählerverwaltung",
        "DeviceManagementText": "Mit DataBreeze können Sie Ihre Zähler konfigurieren und in einer performanten Art und Weise organisieren",
        "DigitalMetering": "Digitale Zählererfassung (IoT)",
        "DigitalMeteringConsultancy": "Beratung für Digitale Zählererfassung",
        "DigitalMeteringConsultancySubText": "Wir kennen uns mit den modernen IoT Technologien wie LoRaWan, Narrowband-IoT aus. Digitale Zählererfassung ist unsere Spezialgebiet.",
        "DigitalMeteringHeader": "IoT all the way",
        "DigitalTwin": "Digitaler Zwilling",
        "DigitalTwinText": "Wir bilden Ihre Daten in einem Digitalen Zwilling ab",
        "DomainDrivenDesign": "Domänengesteuertes Design ",
        "EfficientDevelopmentProcess": "Effizienter Entwicklungsprozess",
        "ElectricMobility": "Elektromobilität",
        "EmailTim": "tim.forkmann@databreeze.de",
        "EnergyControlling": "Energiecontrolling",
        "EnergyControllingText": "Wir bauen für Sie ein funktionierendes und nachhaltiges Energiecontrolling auf            ",
        "EnergyDataAnalysis": "Energiedatenanalysen",
        "EnergyDataConsultancy": "Energiedatenberatung",
        "EnergyDataConsultancySubText": "Wir sind erfahren in Energiedaten, Energiemanagement und modernen Energielösungen.",
        "EnergyDataConsultancyText": "Mit Energiedaten kennen wir uns aus und helfen Ihnen gerne",
        "EnergyDataHeader": "Mit Energiedaten kennen wir uns aus und helfen Ihnen gerne",
        "EnergyDataManagement": "Energiedatenmanagement gemäß ISO 50001",
        "EnergyDataManagementText": "Wir erstellen für Sie Energieberichte gemäß ISO 50001.",
        "EnergyEfficiencyAnalysis": "Energieeffizienzanalyse",
        "EnergyEfficiencyAnalysisText": "Mithilfe von Energiedaten identifizieren wir Ihre Energieoptimierungspotentiale",
        "EnergyEngineeringServices": "Energietechnische Dienstleistungen",
        "EnergyEngineeringServicesText": "Wir bieten Ingenieurleistungen für erneuerbare Energiesysteme, sowie konventionelle Energiesysteme an. ",
        "EnergyEngineeringServiceText": "Energietechnische Dienstleistungen",
        "EnergyMeters": "Energiezählererfassung",
        "EnergyMixCalculation": "Energiemix Berechnungen",
        "EnergyMixCalculationText": "Wir ermitteln aus großen Datenmengen Ihren Energiemix. Berechnung des Primärenergiefaktors, Anteil regenerativer Energien etc. ist für uns kein Problem",
        "EngineeringService": "Energietechnische Dienstleistungen",
        "EngineeringServices": "Technische Dienstleistungen",
        "EngineeringServicesText": "R&I Zeichnungen und Technische Zeichnungen (AutoCAD)",
        "EngineeringServiceText": "Wir bieten Ingenieurleistungen für erneuerbare Energiesysteme, sowie konventionelle Energiesysteme an.",
        "Expertise": "So können wir Ihnen helfen",
        "ExternalAndInternalReporting": "Externes und Internes Berichtswesen",
        "ExternalAndInternalReportingText": "Wir bauen für Sie gerne ein nachhaltiges Reporting System auf.",
        "FollowUs": "Folge uns",
        "FooterHeader": "Starten Sie das nächste Projekt mit uns",
        "FSharpText": "F# und Funktionales Programmieren",
        "GoLive": "Feinabstimmung zur Produktionsreife und GoLive",
        "GoLive1": "Prototyp verfeinern",
        "GoLive2": "Finales Produkt präsentieren",
        "GoLive3": "erneutes Kundenfeedback sammeln",
        "GoLive4": "letztendlich das Produkt liefern",
        "HighPerformanceApps": "Hochleistungsfähige Apps",
        "Home": "Startseite",
        "HowCanWeHelp": "Wie können wir Ihnen helfen?",
        "Imprint": "Impressum",
        "IndustrialAutomation": "Automatisierungstechnik",
        "IndustrialAutomationText": "PLC Programmierung (S7, Allen-Bradley etc.)",
        "IoTCloudSolutions": "IoT Cloud Lösungen (Datenspeicher, IoT Hub, Datenpipelines)",
        "IoTCloudSolutionsText": "Wir implementieren für Sie IoT Cloud Lösungen zur dynamischen Datenverarbeitung Ihre digitalen Zählerdaten.",
        "IoTSolutions": "IoT Lösungen",
        "KnowHowEnergySector": "Knowhow im Energiesektor",
        "KnowHowEnergySectorText": "Wir haben fundierte gesetzliche und fachliche Kenntnisse im Energiesektor und beraten Sie gerne hinsichtlich neuer Datenerfassungstechnologien im Energiebereich.",
        "KnowledgeInTheFollowingAreas": "Wir haben Erfahrung in den folgenden Bereichen",
        "LandingButton": "Wir optimieren Ihre Datenströme",
        "LandingHeader": "A breath of fresh air for your data needs",
        "LandingText": "Modernste IoT-Lösungen, Datenpipelines, Energiedatenberatung und Softwarelösungen",
        "Language": "Sprache",
        "ElectroMobility" : "Elektromobilität",
        "LanguageChanged": "Sprache wurde auf Deutsch geändert",
        "LoadProfileProcessing": "Lastgangverarbeitungsmethoden",
        "LoadProfileProcessingText": "Unser Aggregator ist in der Lage dynamisch Lastgänge zu bilden. Die Ersatzwertbildung und Aggregation der Werte ist in dem Aggregator enthalten.",
        "Maintainability": "Wartbarkeit",
        "ManualMeasures": "Manuelle Zählererfassung",
        "ManualMeasuresText": "Grid zur dynamischen Eingabe von Daten",
        "MarketCommunication": "Marktkommunikation (EDIFACT)",
        "MarketCommunicationText": "Wir nutzen EDIFACT für eine automatisierte technische Verarbeitung von Stamm- und Bewegungsdaten und somit eine einheitliche und zukunftsorientierte Marktkommunikation.",
        "Message": "Mitteilung",
        "MessageSent": "Mitteilung wurde übermittelt",
        "Modularity": "Modularität",
        "NET": "Wir entwickeln überwiegend .NET Software",
        "OpenSourceInfused": "Open Source infundiert",
        "Partner": "Partner",
        "PartnerText": "Hier sind einige unserer Partner",
        "Performant": "Performant",
        "PhoneNumber": "Telefonnummer",
        "PhoneTim": "+1-613-316-0947",
        "PreciseDataVisualization": "Präzise Datenvisualisierung & maßgeschneidertes Benchmarking",
        "PreciseDataVisualizationText": "Wir stellen Ihre Daten anschaulich dar und bieten Ihnen ein perfektes Benchmarking-Tool.",
        "ProcessEngineering": "Verfahrenstechnik",
        "ProcessEngineeringText": "Wir sind spezialisiert auf die Bereiche Verfahrenstechnik und Automatisierungstechnik.",
        "ProjectManagement": "Projektmanagement",
        "ProjectManagementText": "Wir bieten Dienstleistungen von Machbarkeitsstudien bis hin zu Projektmanagement und Implementierung.",
        "ProjectMethodology": "Projektmethodik",
        "ProjectStartup1": "das Problem definieren",
        "ProjectStartup2": "die Anforderungen definieren",
        "ProjectStartup3": "Infrastruktur und Sicherheit definieren",
        "ProjectStartup4": "die Best Practices für die Technologie definieren",
        "ProjectStartupConsultation": "Projektstartberatung",
        "Prototyping1": "Erstellung erster Prototyp oder PoC",
        "Prototyping2": "Kernfunktionalität zeigen",
        "Prototyping3": "Kundenfeedback sammeln",
        "PrototypingAndScripting": "Prototyping und Skripting",
        "ReadMore": "Lesen Sie mehr hier",
        "RegulatoryReports": "Berichte zur Einhaltung gesetzlicher Vorschriften",
        "Reliability": "Zuverlässigkeit",
        "RenewableEnergyConsultancy": "Beratungsleistungen für Erneuerbare Energien",
        "RenewableEnergyConsultancyText": "Machtbarkeitsstudien für Biogasanlagen, Solaranlagen und Wärmepumpen",
        "Reusability": "Wiederverwendbarkeit",
        "SaaS": {
            "CustomReporting": "Kundenspezifische Berichte",
            "DeviceManagement": "Gerätemanagement",
            "LoadProfileManagement": "Lastgangmanagement",
            "MarketCommunication": "Marktkommunikation",
            "ReportManagement": "Berichtsmanagement"
        },
        "SaaSAggregatorText1": "Präzise Datenaggregation und Datenersetzung. Wir führen für Sie Plausibilitäts- und Zuverlässigkeitsprüfungen durch. Der Aggregator listet alle fehlenden und ersetzten Werte auf.",
        "SaaSAggregatorText2": "Zuverlässige und performante Datenaggregation",
        "SaaSAggregatorText3": "Datenaggregation",
        "SaaSAggregatorText4": "Fehlende Werte markieren und auflisten",
        "SaaSAggregatorText5": "Datenersetzung",
        "SaaSAggregatorText6": "Plausibilitäts- und Zuverlässigkeitsprüfungen",
        "SaaSAggregatorText7": "Performante Hintergrundaggregation",
        "SaaSAnalyzerText1": "Mit unserem Analyzer hilft Ihnen beim Analysieren Ihrer Daten. Unser Benchmarking-Tool hilft Ihnen, die Leistung Ihres Systems aufzuzeigen. Der Analyzer berechnet für Sie Ihren erneuerbaren Energieanteil, Ihre CO2-Bilanz und Ihren Primärenergiefaktor.",
        "SaaSAnalyzerText2": "Quickly benchmark your efficiency",
        "SaaSAnalyzerText3": "Benchmarken Sie schnell Ihre Effizienz",
        "SaaSAnalyzerText4": "Energieeffizienzberechnung",
        "SaaSAnalyzerText5": "Berechnen Sie den Anteil erneuerbarer Energien",
        "SaaSAnalyzerText6": "CO2-Bilanzberechnung",
        "SaaSAnalyzerText7": "Berechnung des Primärenergiefaktors",
        "SaaSCollectorText1": "Erfassen Sie Ihre Messdaten ganz einfach mit einem modernen DeviceManagement. Sie können Ihre Daten hochladen (CSV, Excel etc.), ein Dateneingaberaster verwenden, eine Zähler-App verwenden oder Ihren Collector am Standort installieren, der alle Ihre Daten kontinuierlich und vollautomatisch sammelt.",
        "SaaSCollectorText2": "Verbinden Sie Ihre Daten im Handumdrehen",
        "SaaSCollectorText3": "Intuitives Gerätemanagement",
        "SaaSCollectorText4": "Kundenspezifische Firmware-Entwicklung",
        "SaaSCollectorText5": "Manuelle Dateneingabe - Datenraster",
        "SaaSCollectorText6": "Zähler-App",
        "SaaSHeader": "Hier unsere SaaS Pakete",
        "SaaSPriceBasic": "60 €/Anlage/Monat",
        "SaaSPriceEnterprise": "100 €/Anlage/Monat",
        "SaaSPricePro": "80 €/Anlage/Monat",
        "SaaSReportingText1": "Unser Reporting-Tool gibt Ihnen einen Überblick über alle Ihre wiederkehrenden Meldungen. Berichte können für die interne Berichterstattung angepasst werden oder Sie können unseren Berichtswesen zur Einhaltung gesetzlicher Vorschriften für Ihre externe Berichterstattung verwenden.",
        "SaaSReportingText2": "Leistungsstarke und präzise Berichte",
        "SaaSReportingText3": "Leistungsstark",
        "SaaSReportingText4": "Berichterstattung in höchster Datenqualität",
        "SaaSReportingText5": "Berichtswesen zur Einhaltung gesetzlicher Vorschriften",
        "SaaSReportingText6": "Einfache Berichtkonfiguration",
        "SaaSReportingText7": "Berichtsübersicht",
        "SaaSReportingText8": "Berichte können ausgelöst werden",
        "SAFEStackSolutions": "SAFE Stack Lösungen",
        "Security": "Sicherheit",
        "Services": "Dienstleistungen",
        "SqlServer": "Wir sind in der Lage eine umfangreiche Sql Server Infrastruktur zu bearbeiten",
        "Tim": "Tim hat an der TU Berlin studiert und hält einen Master of Science in Regenerative Energiesysteme. Er arbeitet bereits seit über 10 Jahren in der Energiebranche. Seine Karriere startet er als Projektingenieur bei Danpower GmbH. In 2015 wurde als Energiedatenmanager bei der Danpower benannt. In März 2020 wurde er Leiter Energiedaten & Digitalisierung der Danpower. Er leitete ein Team von drei Leuten. Mit seiner Leidenschaft zu F# und seinem fundierten Wissen im Bereich Energietechnik und Energiedaten hat er sich 2021 entschieden DataBreeze zu gründen.",
        "TO": "Technischer Leiter",
        "TrustedTechnologies": "Vertrauenswürdige Technologien",
        "TrustedTechnologiesText": "Wir setzen auf Funktionale Programmiersprachen, Open Source und Microsoft Produkte",
        "UnlimitedMeterPoints": "Unbegrenzte Anzahl an Zählpunkten",
        "UnlimitedStorage": "Unbegrenzter Speicher",
        "UserEngagement": "Benutzerengagement",
        "WaterMetering": "Wasserzählererfassung",
        "WhoAreWe": "Wer sind wir",
        "WhyFsharp": "Warum F#",
        "WhyFsharpText": "Schnelle Markteinführung, niedrige Fehlerquote, kostengünstige Wartung und eine starke .NET-Plattform machen es zu einer erstklassigen Entwicklungssprache für Unternehmen"
    }
}
