import { Record, Union } from "./fable_modules/fable-library.4.10.0/Types.js";
import { unit_type, record_type, class_type, array_type, option_type, list_type, string_type, bool_type, union_type } from "./fable_modules/fable-library.4.10.0/Reflection.js";
import flag_en2 from "../public/png/flag-en2.png";
import flag_fr from "../public/png/flag-fr.png";
import flag_de2 from "../public/png/flag-de2.png";
import { tail, empty, ofArray } from "./fable_modules/fable-library.4.10.0/List.js";
import { parseUrl, Page_$reflection } from "./Router.js";
import { ContactInfo, ContactInfo_$reflection, BlogContent_$reflection } from "./Shared/Shared.js";
import { split } from "./fable_modules/fable-library.4.10.0/String.js";
import { equalsWith } from "./fable_modules/fable-library.4.10.0/Array.js";
import { defaultOf } from "./fable_modules/fable-library.4.10.0/Util.js";
import { now } from "./fable_modules/fable-library.4.10.0/DateOffset.js";
import { RouterModule_urlSegments } from "./fable_modules/Feliz.Router.4.0.0/Router.fs.js";

export class SaaSContent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Collector", "Aggregator", "Analyzer", "Reporting"];
    }
}

export function SaaSContent_$reflection() {
    return union_type("Domain.SaaSContent", [], SaaSContent, () => [[], [], [], []]);
}

export function SaaSContent__get_GetValue(this$) {
    switch (this$.tag) {
        case 1:
            return "Aggregator";
        case 2:
            return "Analyzer";
        case 3:
            return "Reporting";
        default:
            return "Collector";
    }
}

export class Language extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["English", "German", "French"];
    }
}

export function Language_$reflection() {
    return union_type("Domain.Language", [], Language, () => [[], [], []]);
}

export function Language__get_Key(this$) {
    switch (this$.tag) {
        case 1:
            return "de";
        case 2:
            return "fr";
        default:
            return "en";
    }
}

export function Language__get_Value(this$) {
    switch (this$.tag) {
        case 1:
            return "Deutsch";
        case 2:
            return "Français";
        default:
            return "English";
    }
}

export function Language__get_GetLogo(this$) {
    switch (this$.tag) {
        case 0:
            return flag_en2;
        case 2:
            return flag_fr;
        default:
            return flag_de2;
    }
}

export function Language_get_Values() {
    return ofArray([new Language(0, []), new Language(1, []), new Language(2, [])]);
}

export class Model extends Record {
    constructor(CurrentPage, ShowQuickView, ShowLoader, MenuOpen, CurrentPath, SelectedBlogContent, ContactInfo, CurrentTab, SaaSContent, BlogContents, CurrentLanguage, Error$) {
        super();
        this.CurrentPage = CurrentPage;
        this.ShowQuickView = ShowQuickView;
        this.ShowLoader = ShowLoader;
        this.MenuOpen = MenuOpen;
        this.CurrentPath = CurrentPath;
        this.SelectedBlogContent = SelectedBlogContent;
        this.ContactInfo = ContactInfo;
        this.CurrentTab = CurrentTab;
        this.SaaSContent = SaaSContent;
        this.BlogContents = BlogContents;
        this.CurrentLanguage = CurrentLanguage;
        this.Error = Error$;
    }
}

export function Model_$reflection() {
    return record_type("Domain.Model", [], Model, () => [["CurrentPage", Page_$reflection()], ["ShowQuickView", bool_type], ["ShowLoader", bool_type], ["MenuOpen", bool_type], ["CurrentPath", list_type(string_type)], ["SelectedBlogContent", option_type(BlogContent_$reflection())], ["ContactInfo", ContactInfo_$reflection()], ["CurrentTab", list_type(string_type)], ["SaaSContent", SaaSContent_$reflection()], ["BlogContents", array_type(BlogContent_$reflection())], ["CurrentLanguage", Language_$reflection()], ["Error", option_type(class_type("System.Exception"))]]);
}

export const ModelModule_path = (() => {
    const matchValue = split(document.URL, ["#"], void 0, 0);
    return (!equalsWith((x, y) => (x === y), matchValue, defaultOf()) && (matchValue.length === 1)) ? empty() : ((!equalsWith((x_1, y_1) => (x_1 === y_1), matchValue, defaultOf()) && (matchValue.length === 2)) ? tail(ofArray(split(matchValue[1], ["/"], void 0, 0))) : empty());
})();

export const ModelModule_contactInfo = new ContactInfo("", "", now(), "", "");

export const ModelModule_init = new Model(parseUrl(RouterModule_urlSegments(window.location.hash, 1)), false, false, false, ModelModule_path, void 0, ModelModule_contactInfo, ModelModule_path, new SaaSContent(0, []), [], new Language(0, []), void 0);

export class NavigationLink extends Record {
    constructor(Label, Href, Icon) {
        super();
        this.Label = Label;
        this.Href = Href;
        this.Icon = Icon;
    }
}

export function NavigationLink_$reflection() {
    return record_type("Domain.NavigationLink", [], NavigationLink, () => [["Label", string_type], ["Href", string_type], ["Icon", string_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UrlChanged", "ToggleLoader", "ChangeLanguage", "LanguageChanged", "ToggleMenu", "ErrorMsg", "TabToggled", "UrlChangedMarkDown", "ChangePage", "UpdateFullName", "UpdatePhoneNumber", "UpdateMessage", "UpdateEmailAddress", "SendContactInfo", "SavedContactInfo", "SavedContactInfoFailed", "ChangeSaaSContent", "GetBlogEntries", "SelectBlogEntry", "ReceivedBlogEntries", "ReceivedBlogEntriesError"];
    }
}

export function Msg_$reflection() {
    return union_type("Domain.Msg", [], Msg, () => [[["Item", Page_$reflection()]], [], [["Item", Language_$reflection()]], [["Item", Language_$reflection()]], [], [["Item", class_type("System.Exception")]], [["Item", list_type(string_type)]], [["Item", list_type(string_type)]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", ContactInfo_$reflection()]], [["Item", unit_type]], [["Item", class_type("System.Exception")]], [["Item", SaaSContent_$reflection()]], [], [["Item", BlogContent_$reflection()]], [["Item", array_type(BlogContent_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export class TextElements extends Record {
    constructor(Text$, SubText) {
        super();
        this.Text = Text$;
        this.SubText = SubText;
    }
}

export function TextElements_$reflection() {
    return record_type("Domain.TextElements", [], TextElements, () => [["Text", string_type], ["SubText", string_type]]);
}

export class AreaElements extends Record {
    constructor(Consultancy, DigitalMetering, EnergyDataConsultancy, EnergyEngineeringServices, AppDevelopment, ReadMoreText) {
        super();
        this.Consultancy = Consultancy;
        this.DigitalMetering = DigitalMetering;
        this.EnergyDataConsultancy = EnergyDataConsultancy;
        this.EnergyEngineeringServices = EnergyEngineeringServices;
        this.AppDevelopment = AppDevelopment;
        this.ReadMoreText = ReadMoreText;
    }
}

export function AreaElements_$reflection() {
    return record_type("Domain.AreaElements", [], AreaElements, () => [["Consultancy", TextElements_$reflection()], ["DigitalMetering", TextElements_$reflection()], ["EnergyDataConsultancy", TextElements_$reflection()], ["EnergyEngineeringServices", TextElements_$reflection()], ["AppDevelopment", TextElements_$reflection()], ["ReadMoreText", string_type]]);
}

