import { Union } from "./fable_modules/fable-library.4.10.0/Types.js";
import { union_type, string_type, lambda_type, unit_type, int32_type, bool_type } from "./fable_modules/fable-library.4.10.0/Reflection.js";
import * as slick from "slick-carousel/slick/slick.scss";
import * as slick_theme from "slick-carousel/slick/slick-theme.scss";
import { keyValueList } from "./fable_modules/fable-library.4.10.0/MapUtil.js";
import * as react from "react";
import slider_1 from "react-slick/lib/slider";

export class SliderProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Draggable", "Infinite", "Dots", "AdaptiveHeight", "InitialSlide", "Autoplay", "Autoplayspeed", "Speed", "AfterChange", "SlidesToShow", "SlidesToScroll", "CssEase"];
    }
}

export function SliderProps_$reflection() {
    return union_type("Fable.Import.Slick.SliderProps", [], SliderProps, () => [[["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", int32_type]], [["Item", bool_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", lambda_type(int32_type, unit_type)]], [["Item", int32_type]], [["Item", int32_type]], [["Item", string_type]]]);
}

export const slickStyles = slick;

export const slickThemeStyles = slick_theme;

export function slider(b, c) {
    const props = keyValueList(b, 1);
    return react.createElement(slider_1, props, ...c);
}

