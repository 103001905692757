import { FableProps_footer, third, FableProps_tiles, FableProps_heroMini } from "../Props.js";
import { I18n_TranslateWithFallback_E827B83 } from "../fable_modules/Fable.I18Next.0.15.2/Fable.I18Next.fs.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.3.0.0/ElementBuilders.fs.js";
import { Fa_IconOption } from "../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { singleton, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import renewableenergy from "../../public/png/renewableenergy.png";
import energydata from "../../public/png/energydata.png";

export function view(model, dispatch) {
    let elems_12, elms_1, elms, elems_2, elems_1, elems, elems_3, elems_11, elms_2, elems_6, elems_9, elems_8, elems_7;
    const children_3 = ofArray([FableProps_heroMini(I18n_TranslateWithFallback_E827B83("EnergyDataHeader")), createElement("div", createObj(Helpers_combineClasses("content", ofArray([["style", {
        overflow: "auto",
    }], (elems_12 = [(elms_1 = singleton((elms = ofArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-8"], (elems_2 = [createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-ancestor"], (elems_1 = [createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-parent"], ["className", "is-vertical"], (elems = [FableProps_tiles(new Fa_IconOption(11, ["fas fa-lightbulb"]), I18n_TranslateWithFallback_E827B83("KnowHowEnergySector"), I18n_TranslateWithFallback_E827B83("KnowHowEnergySectorText"), false, void 0), FableProps_tiles(new Fa_IconOption(11, ["fas fa-leaf"]), I18n_TranslateWithFallback_E827B83("EnergyMixCalculation"), I18n_TranslateWithFallback_E827B83("EnergyMixCalculationText"), false, void 0), FableProps_tiles(new Fa_IconOption(11, ["fas fa-wind"]), I18n_TranslateWithFallback_E827B83("EnergyDataManagement"), I18n_TranslateWithFallback_E827B83("EnergyDataManagementText"), false, void 0)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-4"], (elems_3 = [createElement("img", {
        src: renewableenergy,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))), createElement("section", {
        className: "section",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), createElement("section", createObj(Helpers_combineClasses("section", ofArray([["style", {
        backgroundColor: third,
        color: "#FFFFFF",
        textDecorationColor: "#0000FF",
    }], (elems_11 = [(elms_2 = ofArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-4"], (elems_6 = [createElement("img", {
        src: energydata,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-8"], (elems_9 = [createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-ancestor"], (elems_8 = [createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-parent"], ["className", "is-vertical"], (elems_7 = [FableProps_tiles(new Fa_IconOption(11, ["fas fa-tachometer-alt"]), I18n_TranslateWithFallback_E827B83("EnergyControlling"), I18n_TranslateWithFallback_E827B83("EnergyControllingText"), true, void 0), FableProps_tiles(new Fa_IconOption(11, ["fas fa-chart-line"]), I18n_TranslateWithFallback_E827B83("EnergyEfficiencyAnalysis"), I18n_TranslateWithFallback_E827B83("EnergyEfficiencyAnalysisText"), true, void 0), FableProps_tiles(new Fa_IconOption(11, ["fas fa-industry"]), I18n_TranslateWithFallback_E827B83("DigitalTwin"), I18n_TranslateWithFallback_E827B83("DigitalTwinText"), true, void 0)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))), FableProps_footer(I18n_TranslateWithFallback_E827B83("FooterHeader"), I18n_TranslateWithFallback_E827B83("ContactUs"))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])]))))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_3)),
    });
}

