import { FableProps_footer, FableProps_tiles, third, FableProps_heroMini } from "../Props.js";
import { I18n_TranslateWithFallback_E827B83 } from "../fable_modules/Fable.I18Next.0.15.2/Fable.I18Next.fs.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.3.0.0/ElementBuilders.fs.js";
import processengineering from "../../public/png/processengineering.png";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { singleton, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { Fa_IconOption } from "../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import $0033ddrawing from "../../public/png/3ddrawing.png";

export function view(model, dispatch) {
    let elems_12, elems_5, elms, elems, elems_3, elems_2, elems_1, elms_2, elms_1, elems_8, elems_7, elems_6, elems_9;
    const children_3 = ofArray([FableProps_heroMini(I18n_TranslateWithFallback_E827B83("EnergyEngineeringServicesHeader")), createElement("div", createObj(Helpers_combineClasses("content", ofArray([["style", {
        overflow: "auto",
    }], (elems_12 = [createElement("section", createObj(Helpers_combineClasses("section", ofArray([["style", {
        backgroundColor: third,
        color: "#FFFFFF",
        textDecorationColor: "#0000FF",
    }], (elems_5 = [(elms = ofArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-4"], (elems = [createElement("img", {
        src: processengineering,
        style: {
            height: 500,
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-8"], (elems_3 = [createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-ancestor"], (elems_2 = [createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-parent"], ["className", "is-vertical"], (elems_1 = [FableProps_tiles(new Fa_IconOption(11, ["fas fa-microchip"]), I18n_TranslateWithFallback_E827B83("IndustrialAutomation"), I18n_TranslateWithFallback_E827B83("IndustrialAutomationText"), true, void 0), FableProps_tiles(new Fa_IconOption(11, ["fas fa-drafting-compass"]), I18n_TranslateWithFallback_E827B83("EngineeringServices"), I18n_TranslateWithFallback_E827B83("EngineeringServicesText"), true, void 0), FableProps_tiles(new Fa_IconOption(11, ["fas fa-industry"]), I18n_TranslateWithFallback_E827B83("RenewableEnergyConsultancy"), I18n_TranslateWithFallback_E827B83("RenewableEnergyConsultancyText"), true, void 0)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))), (elms_2 = singleton((elms_1 = ofArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-8"], (elems_8 = [createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-ancestor"], (elems_7 = [createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-parent"], ["className", "is-vertical"], (elems_6 = [FableProps_tiles(new Fa_IconOption(11, ["fas fa-solar-panel"]), I18n_TranslateWithFallback_E827B83("EnergyEngineeringServices"), I18n_TranslateWithFallback_E827B83("EnergyEngineeringServicesText"), false, void 0), FableProps_tiles(new Fa_IconOption(11, ["fas fa-industry"]), I18n_TranslateWithFallback_E827B83("ProcessEngineering"), I18n_TranslateWithFallback_E827B83("ProcessEngineeringText"), false, void 0), FableProps_tiles(new Fa_IconOption(11, ["fas fa-project-diagram"]), I18n_TranslateWithFallback_E827B83("ProjectManagement"), I18n_TranslateWithFallback_E827B83("ProjectManagementText"), false, void 0)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-4"], (elems_9 = [createElement("img", {
        src: $0033ddrawing,
        style: {
            height: 500,
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))), createElement("section", {
        className: "section",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    })), FableProps_footer(I18n_TranslateWithFallback_E827B83("FooterHeader"), I18n_TranslateWithFallback_E827B83("ContactUs"))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])]))))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_3)),
    });
}

