import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.3.0.0/ElementBuilders.fs.js";
import { FableProps_footer, FableProps_whiteH3, FableProps_iconTileWithTextDarkBackground, FableProps_faSpanBullet, FableProps_heroMini, primary, FableProps_faIcon, third } from "../Props.js";
import { singleton, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { Fa_IconOption, Fa_ISize } from "../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { I18n_TranslateWithFallback_E827B83 } from "../fable_modules/Fable.I18Next.0.15.2/Fable.I18Next.fs.js";

export function cardElectroMobility(area, liElements, icon) {
    let elems_1, elms, elms_1;
    const elms_2 = ofArray([createElement("header", createObj(Helpers_combineClasses("card-header", ofArray([["style", {
        backgroundColor: third,
    }], (elems_1 = [createElement("p", createObj(Helpers_combineClasses("card-header-title", ofArray([["children", area], ["style", {
        color: "#FFFFFF",
    }]])))), (elms = singleton(FableProps_faIcon(icon, new Fa_ISize(3, []), primary)), createElement("span", {
        className: "card-header-icon",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), (elms_1 = singleton(createElement("ul", {
        style: {
            listStyleType: "none",
        },
        children: Interop_reactApi.Children.toArray(Array.from(liElements)),
    })), createElement("div", {
        className: "card-content",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))]);
    return createElement("div", {
        className: "card",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    });
}

export function view(model, dispatch) {
    let elems_20, elms_1, elms, elems, children, children_2, children_4, children_6, elems_1, children_8, children_10, children_12, elems_2, children_14, children_16, children_18, children_20, elems_3, children_22, children_24, children_26, children_28, children_30, children_32, elems_19, elms_7, elms_2, elms_3, elms_4, elms_5, elms_6, elms_14, elms_8, elms_9, elms_10, elms_11, elms_12, elms_13;
    const children_49 = ofArray([FableProps_heroMini(I18n_TranslateWithFallback_E827B83("ProjectMethodology")), createElement("div", createObj(Helpers_combineClasses("content", ofArray([["style", {
        overflow: "auto",
    }], (elems_20 = [(elms_1 = singleton((elms = ofArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-3"], (elems = [cardElectroMobility(I18n_TranslateWithFallback_E827B83("ProjectStartupConsultation"), ofArray([(children = singleton(FableProps_faSpanBullet(primary, I18n_TranslateWithFallback_E827B83("ProjectStartup1"))), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })), (children_2 = singleton(FableProps_faSpanBullet(primary, I18n_TranslateWithFallback_E827B83("ProjectStartup2"))), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    })), (children_4 = singleton(FableProps_faSpanBullet(primary, I18n_TranslateWithFallback_E827B83("ProjectStartup3"))), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
    })), (children_6 = singleton(FableProps_faSpanBullet(primary, I18n_TranslateWithFallback_E827B83("ProjectStartup4"))), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
    }))]), new Fa_IconOption(11, ["fas fa-pencil-alt"]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-3"], (elems_1 = [cardElectroMobility(I18n_TranslateWithFallback_E827B83("PrototypingAndScripting"), ofArray([(children_8 = singleton(FableProps_faSpanBullet(primary, I18n_TranslateWithFallback_E827B83("Prototyping1"))), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_8)),
    })), (children_10 = singleton(FableProps_faSpanBullet(primary, I18n_TranslateWithFallback_E827B83("Prototyping2"))), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_10)),
    })), (children_12 = singleton(FableProps_faSpanBullet(primary, I18n_TranslateWithFallback_E827B83("Prototyping3"))), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_12)),
    }))]), new Fa_IconOption(11, ["fas fa-bezier-curve"]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-3"], (elems_2 = [cardElectroMobility(I18n_TranslateWithFallback_E827B83("GoLive"), ofArray([(children_14 = singleton(FableProps_faSpanBullet(primary, I18n_TranslateWithFallback_E827B83("GoLive1"))), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_14)),
    })), (children_16 = singleton(FableProps_faSpanBullet(primary, I18n_TranslateWithFallback_E827B83("GoLive2"))), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_16)),
    })), (children_18 = singleton(FableProps_faSpanBullet(primary, I18n_TranslateWithFallback_E827B83("GoLive3"))), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_18)),
    })), (children_20 = singleton(FableProps_faSpanBullet(primary, I18n_TranslateWithFallback_E827B83("GoLive4"))), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_20)),
    }))]), new Fa_IconOption(11, ["fas fa-rocket"]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-3"], (elems_3 = [cardElectroMobility(I18n_TranslateWithFallback_E827B83("ContinuousImprovement"), ofArray([(children_22 = singleton(FableProps_faSpanBullet(primary, I18n_TranslateWithFallback_E827B83("ContinuousImprovement1"))), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_22)),
    })), (children_24 = singleton(FableProps_faSpanBullet(primary, I18n_TranslateWithFallback_E827B83("ContinuousImprovement2"))), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_24)),
    })), (children_26 = singleton(FableProps_faSpanBullet(primary, I18n_TranslateWithFallback_E827B83("ContinuousImprovement3"))), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_26)),
    })), (children_28 = singleton(FableProps_faSpanBullet(primary, I18n_TranslateWithFallback_E827B83("ContinuousImprovement4"))), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_28)),
    })), (children_30 = singleton(FableProps_faSpanBullet(primary, I18n_TranslateWithFallback_E827B83("ContinuousImprovement5"))), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_30)),
    })), (children_32 = singleton(FableProps_faSpanBullet(primary, I18n_TranslateWithFallback_E827B83("ContinuousImprovement6"))), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_32)),
    }))]), new Fa_IconOption(11, ["fas fa-phone-square"]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))), createElement("section", {
        className: "section",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), createElement("section", createObj(Helpers_combineClasses("section", ofArray([["style", {
        backgroundColor: third,
        textAlign: "center",
    }], (elems_19 = [createElement("h1", createObj(Helpers_combineClasses("title", ofArray([["className", "has-text-primary"], ["children", I18n_TranslateWithFallback_E827B83("CorePhilosophy")], ["style", {
        textAlign: "center",
    }]])))), (elms_7 = ofArray([(elms_2 = singleton(FableProps_iconTileWithTextDarkBackground(new Fa_IconOption(11, ["fas fa-lightbulb"]), new Fa_ISize(4, []), I18n_TranslateWithFallback_E827B83("Creativity"))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    })), (elms_3 = singleton(FableProps_iconTileWithTextDarkBackground(new Fa_IconOption(11, ["fas fa-bullseye"]), new Fa_ISize(4, []), I18n_TranslateWithFallback_E827B83("Correctness"))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    })), (elms_4 = singleton(FableProps_iconTileWithTextDarkBackground(new Fa_IconOption(11, ["fas fa-tachometer-alt"]), new Fa_ISize(4, []), I18n_TranslateWithFallback_E827B83("Performant"))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    })), (elms_5 = singleton(FableProps_iconTileWithTextDarkBackground(new Fa_IconOption(11, ["fas fa-shield-alt"]), new Fa_ISize(4, []), I18n_TranslateWithFallback_E827B83("Security"))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    })), (elms_6 = singleton(FableProps_iconTileWithTextDarkBackground(new Fa_IconOption(11, ["fas fa-running"]), new Fa_ISize(4, []), I18n_TranslateWithFallback_E827B83("Reliability"))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
    }))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
    })), createElement("hr", {}), (elms_14 = ofArray([(elms_8 = singleton(FableProps_whiteH3(I18n_TranslateWithFallback_E827B83("DomainDrivenDesign"))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
    })), (elms_9 = singleton(FableProps_whiteH3(I18n_TranslateWithFallback_E827B83("CustomerFocused"))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
    })), (elms_10 = singleton(FableProps_whiteH3(I18n_TranslateWithFallback_E827B83("Maintainability"))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
    })), (elms_11 = singleton(FableProps_whiteH3(I18n_TranslateWithFallback_E827B83("Reusability"))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
    })), (elms_12 = singleton(FableProps_whiteH3(I18n_TranslateWithFallback_E827B83("Modularity"))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_12)),
    })), (elms_13 = singleton(FableProps_whiteH3(I18n_TranslateWithFallback_E827B83("OpenSourceInfused"))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_13)),
    }))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_14)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])])))), FableProps_footer(I18n_TranslateWithFallback_E827B83("FooterHeader"), I18n_TranslateWithFallback_E827B83("ContactUs"))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])]))))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_49)),
    });
}

