import { class_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { createElement } from "react";
import React from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { singleton, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { Interop_reactApi as Interop_reactApi_1 } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import react_highlight from "react-highlight";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import tf_image from "../../public/png/tf_image.png";
import CanadaEnergyFunding from "../../public/png/blog/CanadaEnergyFunding.png";
import RenewableEnergySnow from "../../public/png/blog/RenewableEnergySnow.png";
import RenewableEnergyGermany from "../../public/png/blog/RenewableEnergyGermany.png";
import EnergyPlant from "../../public/png/blog/EnergyPlant.png";
import DataAct from "../../public/png/blog/DataAct.png";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.3.0.0/ElementBuilders.fs.js";
import { toLongDateString } from "../fable_modules/fable-library.4.10.0/Date.js";
import { map, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { Interop_reactApi as Interop_reactApi_2 } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import react_markdown from "react-markdown";
import { FableProps_breadcrumb, FableProps_heroMini } from "../Props.js";
import { value as value_62 } from "../fable_modules/fable-library.4.10.0/Option.js";
import { Page } from "../Router.js";

export class Highlight {
    constructor() {
    }
}

export function Highlight_$reflection() {
    return class_type("Views.Blog.Highlight", void 0, Highlight);
}

/**
 * Renders a code block from markdown using react-highlight.
 * Injects sample React components when the code block has language of the format <language>:<sample-name>
 */
export function CodeBlockRenderer$0027(input) {
    let elems;
    const properties = ofArray([["className", "fsharp"], (elems = input.codeProps.children, ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]);
    return Interop_reactApi_1.createElement(react_highlight, createObj(properties));
}

export function codeBlockRenderer(codeProps) {
    return createElement(CodeBlockRenderer$0027, {
        codeProps: codeProps,
    });
}

export function RenderMarkdown(blogContent) {
    let elems_6, elms_3, elms, elems, elms_2, elms_1, elems_5, properties_18;
    const creatorImage = (blogContent.Author === "Tim Forkmann") ? tf_image : CanadaEnergyFunding;
    let imageSrc;
    const matchValue_1 = blogContent.BlogId | 0;
    imageSrc = ((matchValue_1 === 1) ? RenewableEnergySnow : ((matchValue_1 === 2) ? RenewableEnergyGermany : ((matchValue_1 === 3) ? RenewableEnergyGermany : ((matchValue_1 === 4) ? EnergyPlant : ((matchValue_1 === 5) ? DataAct : tf_image)))));
    return createElement("section", createObj(Helpers_combineClasses("section", singleton((elems_6 = [(elms_3 = ofArray([(elms = singleton(createElement("figure", createObj(Helpers_combineClasses("image", ofArray([["className", "is-96x96"], ["className", "is-rounded"], (elems = [createElement("img", {
        alt: "Placeholder image",
        src: creatorImage,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))))), createElement("div", {
        className: "media-left",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (elms_2 = ofArray([createElement("p", createObj(Helpers_combineClasses("title", ofArray([["className", "is-4"], ["children", "By " + blogContent.Author]])))), createElement("p", createObj(Helpers_combineClasses("subtitle", ofArray([["className", "is-6"], ["children", "Posted: " + toLongDateString(blogContent.Created)]])))), createElement("p", createObj(Helpers_combineClasses("subtitle", ofArray([["className", "is-6"], ["children", "Category: " + blogContent.Category]])))), (elms_1 = toList(delay(() => map((tag) => createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "is-primary"], ["children", tag]])))), blogContent.Tags))), createElement("div", {
        className: "tags",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))]), createElement("div", {
        className: "media-content",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))]), createElement("article", {
        className: "media",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    })), createElement("h1", {
        style: {
            textAlign: "center",
        },
        children: blogContent.BlogEntryName,
    }), createElement("p", {
        style: {
            textAlign: "center",
        },
        children: blogContent.Description,
    }), createElement("figure", createObj(Helpers_combineClasses("image", ofArray([["style", {
        textAlign: "center",
    }], (elems_5 = [createElement("img", {
        style: {
            height: 288,
            width: 512,
        },
        src: imageSrc,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))), (properties_18 = ofArray([["children", blogContent.MarkdownContent], ["escapeHtml", false], ["components", {
        code: (props_20) => codeBlockRenderer({
            className: props_20.className || "",
            children: props_20.children || [],
            isInline: props_20.inline || false,
        }),
    }]]), Interop_reactApi_2.createElement(react_markdown, createObj(properties_18)))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])))));
}

export function view(model, dispatch) {
    let elems;
    const matchValue = model.SelectedBlogContent;
    if (matchValue == null) {
        return createElement("div", {});
    }
    else {
        const content = matchValue;
        const children = ofArray([FableProps_heroMini(content.BlogEntryName), FableProps_breadcrumb("Blogs", content.BlogEntryName, new Page(4, [value_62(model.SelectedBlogContent).BlogId])), createElement("div", createObj(Helpers_combineClasses("content", singleton((elems = [createElement(RenderMarkdown, content)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])))))]);
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }
}

