{
    "translation": {
        "About": "À propos de nous",
        "AboutText": "Nous sommes une jeune entreprise de consulting informatique. Nous vous aidons à développer et à gérer votre site web. Nous vous proposons une multitude de produits et de services qui permettent de réaliser votre site web. Nous proposons également une multitude de composants qui peuvent vous aider à développer votre site web.",
        "Address": "Adresse",
        "AppDevelopment": "Développement d'applications",
        "AppDevelopmentHeader": "Nous développons des apps pour vous",
        "AppDevelopmentReactNative": "Développement d'applications multiplateformes avec React Native et Expo",
        "AppDevelopmentReactNativeText": "Nous développons des applications pour Android et iOS avec React Native et Expo",
        "AppDevelopmentText1": "Développement d'applications de pointe",
        "AppDevelopmentText2": "Internationalisation avec l'i18n",
        "AppDevelopmentText3": "Diagrammes interactifs avec React Native Skia",
        "AppDevelopmentText4": "Applications en marque blanche",
        "AppDevelopmentText5": "Développement d'applications mobiles hautes performances pour iOS et Android",
        "Azure": "Nous sommes des architectes de solutions cloud Azure expérimentés.",
        "AzureHeader": "Solutions cloud Azure.",
        "Basic": "Les bases sont incluses",
        "Blog": "Dernières mises à jour, actualités et vues",
        "BookConsultation": "Prendre rendez-vous maintenant",
        "BusinessAreas": "Secteurs d'activité",
        "CEO": "Conseiller principal en logiciels / Fondateur",
        "CollectorText": "Utilisez notre collecteur pour numériser vos sites",
        "CompanyCityAndPostalCode": "K1S 5A2, Ottawa, ON",
        "CompanyCountry": "Canada",
        "CompanyName": "DataBreeze Inc.",
        "CompanyStreet": "30 Marco Lane",
        "CompetenceDigitalMetering": "Compétence en matière de compteurs numériques",
        "CompetenceDigitalMeteringText": "Nous vous aidons volontiers à saisir les valeurs numériques de vos compteurs. Nous nous y connaissons dans le domaine de l'IoT !",
        "Consultancy": "Conseil en informatique",
        "ConsultancySubText": "Nous vous aidons à mettre en œuvre des solutions informatiques durables et modernes. Nous mettons en œuvre des processus de validation des données afin de garantir que la qualité de vos données est conforme aux exigences réglementaires. Nous avons de l'expérience dans le développement de systèmes de déclaration sur mesure qui mettent fin aux déclarations récurrentes aux institutions officielles.",
        "ConsultingHeader": "Nous proposons les services de conseil informatique suivants",
        "Contact": "Contact",
        "ContactCEO": "Directeur général: Tim Forkmann",
        "ContactEmail": "Adresse électronique",
        "ContactEmailMessage": "Ou écrivez-nous",
        "ContactFullName": "Nom",
        "ContactPhone": "Comment nous contacter",
        "ContactText": "Que pouvons-nous faire pour vous ?",
        "ContactUs": "Contactez-nous",
        "ContinuousImprovement": "Amélioration et maintenance continues",
        "ContinuousImprovement1": "Surveillance du système",
        "ContinuousImprovement2": "Mises à jour du logiciel",
        "ContinuousImprovement3": "Correctifs de sécurité",
        "ContinuousImprovement4": "Corrections d'erreurs",
        "ContinuousImprovement5": "Améliorations des prestations",
        "ContinuousImprovement6": "Tests de système pour garantir l'intégrité des données critiques",
        "CorePhilosophy": "Philosophie centrale",
        "Correctness": "Exactitude",
        "CostEffectiveSolutions": "Solutions à coûts optimisés",
        "Creativity": "Créativité",
        "CrossPlatform": "Multiplateforme",
        "CustomerFocused": "Orienté vers le client",
        "CustomizedFirmware": "Développement de micrologiciels personnalisés",
        "CustomizedFirmwareText": "Nous pouvons adapter le firmware de l'appareil à vos besoins.",
        "CustomizedRegulatoryReports": "Rapports de données personnalisés pour la conformité réglementaire",
        "CustomizedRegulatoryReportsText": "Vous avez besoin de rapports de données personnalisés pour la conformité réglementaire ? Nous pouvons vous aider.",
        "DataAnalysis": "Analyse et correction des données",
        "DataAnalysisText": "Nous effectuons volontiers des analyses de données pour vous et pouvons également contrôler vos données et les corriger si nécessaire.",
        "DataExport": "Exportation de données et interfaces",
        "DataPipelines": "Mise en place de pipelines de données",
        "DataPipelinesText": "Nous optimisons vos flux de données et mettons en œuvre des pipelines de données modernes pour vous.",
        "DataProtocol": "Nombreux protocoles de transmission de données",
        "DataProtocolText": "Modbus, GPIO, S7, MBus",
        "DataQuality": "Optimisation de la qualité des données et des processus",
        "DataQualityText": "Les infrastructures informatiques existantes présentent souvent des erreurs et des lacunes importantes dans les données, ce qui entraîne des problèmes dans la création correcte de rapports. Nous mettons en œuvre pour vous des processus de validation des données et de remplacement des valeurs afin de pouvoir produire à tout moment des rapports plausibles.",
        "DataSolutions": "Solutions de données",
        "DataSolutionsText": "Nous vous aidons à résoudre vos problèmes",
        "DataVisualization": "Visualisation et analyse des données",
        "DeviceManagement": "Gestion des compteurs",
        "DeviceManagementText": "DataBreeze vous permet de configurer vos compteurs et de les organiser de manière performante.",
        "DigitalMetering": "Relevé numérique des compteurs (IoT)",
        "DigitalMeteringConsultancy": "Conseil pour la saisie numérique des compteurs",
        "DigitalMeteringConsultancySubText": "Nous connaissons les technologies IoT modernes comme LoRaWan, Narrowband-IoT. L'enregistrement numérique des compteurs est notre spécialité.",
        "DigitalMeteringHeader": "L'IdO sur toute la ligne",
        "DigitalTwin": "Jumeau numérique",
        "DigitalTwinText": "Nous reproduisons vos données dans un jumeau numérique",
        "DomainDrivenDesign": "Conception orientée domaine",
        "EfficientDevelopmentProcess": "Un processus de développement efficace",
        "ElectricMobility": "Mobilité électrique",
        "EmailTim": "tim.forkmann@databreeze.de",
        "EnergyControlling": "Contrôle de l'énergie",
        "EnergyControllingText": "Nous mettons en place pour vous un contrôle énergétique fonctionnel et durable",
        "EnergyDataAnalysis": "Analyse des données énergétiques",
        "EnergyDataConsultancy": "Conseil en données énergétiques",
        "EnergyDataConsultancySubText": "Nous sommes expérimentés dans les données énergétiques, la gestion de l'énergie et les solutions énergétiques modernes.",
        "EnergyDataConsultancyText": "Nous nous y connaissons en données énergétiques et nous vous aidons volontiers.",
        "EnergyDataHeader": "Nous nous y connaissons en données énergétiques et nous vous aidons volontiers.",
        "EnergyDataManagement": "Gestion des données énergétiques selon la norme ISO 50001",
        "EnergyDataManagementText": "Nous établissons pour vous des rapports énergétiques conformément à la norme ISO 50001.",
        "EnergyEfficiencyAnalysis": "Analyse de l'efficacité énergétique",
        "EnergyEfficiencyAnalysisText": "À l'aide des données énergétiques, nous identifions vos potentiels d'optimisation énergétique.",
        "EnergyEngineeringServices": "Services énergétiques",
        "EnergyEngineeringServicesText": "Nous proposons des services d'ingénierie pour les systèmes d'énergie renouvelable, ainsi que pour les systèmes d'énergie conventionnelle.",
        "EnergyEngineeringServiceText": "Services énergétiques",
        "EnergyMeters": "Compteurs d'énergie",
        "EnergyMixCalculation": "Energiemix Berechnungen",
        "EnergyMixCalculationText": "Nous déterminons votre mix énergétique à partir de grandes quantités de données. Le calcul du facteur d'énergie primaire, de la part d'énergie renouvelable, etc. ne nous pose aucun problème.",
        "EngineeringService": "Services énergétiques",
        "EngineeringServices": "Services techniques",
        "EngineeringServicesText": "Dessins R&I et Dessins techniques (AutoCAD)",
        "EngineeringServiceText": "Nous proposons des services d'ingénierie pour les systèmes d'énergie renouvelable, ainsi que pour les systèmes d'énergie conventionnelle.",
        "Expertise": "Voici comment nous pouvons vous aider",
        "ExternalAndInternalReporting": "Rapports externes et internes",
        "ExternalAndInternalReportingText": "Nous mettons volontiers en place pour vous un système de reporting durable.",
        "FollowUs": "Suivez-nous",
        "FooterHeader": "Lancez votre prochain projet avec nous",
        "FSharpText": "F# et programmation fonctionnelle",
        "GoLive": "Réglage fin jusqu'au stade de la production et GoLive",
        "GoLive1": "Affiner le prototype",
        "GoLive2": "Présenter le produit final",
        "GoLive3": "recueillir à nouveau les commentaires des clients",
        "GoLive4": "livrer finalement le produit",
        "HighPerformanceApps": "Des apps très performantes",
        "Home": "Accueil",
        "HowCanWeHelp": "Comment pouvons-nous vous aider?",
        "Imprint": "Mentions légales",
        "IndustrialAutomation": "Technique d'automatisation",
        "IndustrialAutomationText": "Programmation PLC (S7, Allen-Bradley, etc.)",
        "IoTCloudSolutions": "Solutions cloud IoT (stockage de données, hub IoT, pipelines de données)",
        "IoTCloudSolutionsText": "Nous mettons en œuvre pour vous des solutions IoT Cloud pour le traitement dynamique des données de vos compteurs numériques.",
        "IoTSolutions": "Solutions IoT",
        "AppDevelopmentSubText": "Nous développons pour vous des applications React Native Expo multiplateformes",
        "KnowHowEnergySector": "Savoir-faire dans le secteur de l'énergie",
        "KnowHowEnergySectorText": "Nous disposons de connaissances juridiques et techniques approfondies dans le secteur de l'énergie et nous vous conseillons volontiers sur les nouvelles technologies de collecte de données dans le domaine de l'énergie.",
        "KnowledgeInTheFollowingAreas": "Connaissances dans les domaines suivants",
        "LandingButton": "Nous optimisons vos flux de données",
        "LandingHeader": "Une bouffée d'air frais pour vos besoins en données",
        "LandingText": "Solutions IoT de pointe, pipelines de données, conseil en données énergétiques et solutions logicielles",
        "Language": "Langue",
        "LanguageChanged": "La langue a été changée en français",
        "LoadProfileProcessing": "Méthodes de traitement de la courbe de charge",
        "LoadProfileProcessingText": "Notre agrégateur est capable de créer des courbes de charge de manière dynamique. Le calcul des valeurs de remplacement et l'agrégation des valeurs sont inclus dans l'agrégateur.",
        "Maintainability": "Maintenabilité",
        "ManualMeasures": "Relevé manuel des compteurs",
        "ManualMeasuresText": "Grille pour la saisie dynamique des données",
        "MarketCommunication": "Communication du marché (EDIFACT)",
        "MarketCommunicationText": "Nous utilisons EDIFACT pour un traitement technique automatisé des données de base et des données de mouvement et donc pour une communication de marché uniforme et orientée vers l'avenir.",
        "Message": "Communiqué",
        "MessageSent": "La notification a été transmise",
        "Modularity": "Modularité",
        "NET": "Nous développons principalement des logiciels .NET",
        "OpenSourceInfused": "Open Source infusé",
        "Partner": "Associé",
        "PartnerText": "Voici quelques-uns de nos partenaires",
        "Performant": "Performant",
        "PhoneNumber": "Numéro de téléphone",
        "PhoneTim": "+1-613-3160947",
        "PreciseDataVisualization": "Visualisation précise des données & benchmarking sur mesure",
        "PreciseDataVisualizationText": "Nous présentons vos données de manière claire et vous offrons un outil de benchmarking parfait.",
        "ProcessEngineering": "Technique des procédés",
        "ProcessEngineeringText": "Nous sommes spécialisés dans les domaines de la technologie des procédés et de l'automatisation.",
        "ProjectManagement": "Gestion de projet",
        "ProjectManagementText": "Nous proposons des services allant des études de faisabilité à la gestion de projet et à la mise en œuvre.",
        "ProjectMethodology": "Méthodologie de projet",
        "ProjectStartup1": "définir le problème",
        "ProjectStartup2": "définir les exigences",
        "ProjectStartup3": "Définir l'infrastructure et la sécurité",
        "ProjectStartup4": "définir les meilleures pratiques en matière de technologie",
        "ProjectStartupConsultation": "Conseils pour le lancement de projets",
        "Prototyping1": "Création du premier prototype ou PoC",
        "Prototyping2": "Montrer la fonctionnalité de base",
        "Prototyping3": "Recueillir les commentaires des clients",
        "PrototypingAndScripting": "Prototypage et scripting",
        "ReadMore": "Lire la suite ici",
        "RegulatoryReports": "Rapports de conformité réglementaire",
        "Reliability": "Fiabilité",
        "RenewableEnergyConsultancy": "Services de conseil en matière d'énergies renouvelables",
        "RenewableEnergyConsultancyText": "Études de faisabilité pour les installations de biogaz, les installations solaires et les pompes à chaleur",
        "Reusability": "Possibilité de réutilisation",
        "SAFEStackSolutions": "Solutions SAFE Stack",
        "Security": "Sécurité",
        "Services": "Services",

        "SqlServer": "Nous sommes en mesure de traiter une vaste infrastructure de serveurs Sql",
        "Tim": "Tim a étudié à l'Université technique de Berlin et détient un Master of Science en systèmes énergétiques régénératifs. Il travaille depuis plus de 10 ans dans le secteur de l'énergie. Il débute sa carrière en tant qu'ingénieur de projet chez Danpower GmbH. En 2015, il est nommé responsable des données énergétiques chez Danpower. En mars 2020, il devient responsable des données énergétiques et de la numérisation chez Danpower. Il a dirigé une équipe de trois personnes. Avec sa passion pour le F# et ses connaissances approfondies dans le domaine de l'énergie et des données énergétiques, il a décidé de fonder DataBreeze en 2021.",
        "TO": "Directeur technique",
        "TrustedTechnologies": "Technologies de confiance",
        "TrustedTechnologiesText": "Nous utilisons des langages de programmation fonctionnels, des logiciels libres et des produits Microsoft.",
        "UnlimitedMeterPoints": "Nombre illimité de points de comptage",
        "UnlimitedStorage": "Mémoire illimitée",
        "UserEngagement": "Engagement des utilisateurs",
        "WaterMetering": "Comptage d'eau",
        "WhoAreWe": "Qui sommes-nous ?",
        "WhyFsharp": "Pourquoi F# ?",
        "WhyFsharpText": "Une mise sur le marché rapide, un faible taux d'erreur, une maintenance peu coûteuse et une solide plateforme .NET en font un langage de développement de premier ordre pour les entreprises."
    }
}
