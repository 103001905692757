import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.3.0.0/ElementBuilders.fs.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import header from "../../public/png/header.png";
import { I18n_TranslateWithFallback_E827B83 } from "../fable_modules/Fable.I18Next.0.15.2/Fable.I18Next.fs.js";
import { Page, getHref } from "../Router.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { FableProps_footer, Slick_areaSlider } from "../Props.js";
import { AreaElements, TextElements } from "../Domain.js";

export function view(model, dispatch) {
    let elems_3;
    return createElement("div", createObj(Helpers_combineClasses("content", singleton_1((elems_3 = toList(delay(() => {
        let elems_2, elems_1, elems;
        return append(singleton(createElement("section", createObj(Helpers_combineClasses("hero", ofArray([["className", "is-fullheight-with-navbar"], ["style", {
            textAlign: "center",
            backgroundSize: "cover",
            backgroundPosition: "no-repeat center center fixed",
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),url(${header})`,
        }], (elems_2 = [createElement("div", createObj(Helpers_combineClasses("hero-body", ofArray([["style", {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
        }], (elems_1 = [createElement("div", createObj(singleton_1((elems = [createElement("h1", {
            className: "is-hidden-mobile",
            style: {
                fontSize: 70 + "px",
                color: "#FFFFFF",
            },
            children: I18n_TranslateWithFallback_E827B83("LandingHeader"),
        }), createElement("h1", {
            className: "is-hidden-desktop",
            style: {
                fontSize: 40 + "px",
                color: "#FFFFFF",
            },
            children: I18n_TranslateWithFallback_E827B83("LandingHeader"),
        }), createElement("h4", {
            className: "has-text-white",
            children: I18n_TranslateWithFallback_E827B83("LandingText"),
        }), createElement("a", createObj(Helpers_combineClasses("button", ofArray([["href", getHref(new Page(6, []))], ["className", "is-primary"], ["children", I18n_TranslateWithFallback_E827B83("LandingButton")]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))))), delay(() => append(singleton(Slick_areaSlider(new AreaElements(new TextElements(I18n_TranslateWithFallback_E827B83("Consultancy"), I18n_TranslateWithFallback_E827B83("ConsultancySubText")), new TextElements(I18n_TranslateWithFallback_E827B83("DigitalMeteringConsultancy"), I18n_TranslateWithFallback_E827B83("DigitalMeteringConsultancySubText")), new TextElements(I18n_TranslateWithFallback_E827B83("EnergyDataConsultancy"), I18n_TranslateWithFallback_E827B83("EnergyDataConsultancySubText")), new TextElements(I18n_TranslateWithFallback_E827B83("EnergyEngineeringServices"), I18n_TranslateWithFallback_E827B83("EnergyEngineeringServicesSubText")), new TextElements(I18n_TranslateWithFallback_E827B83("AppDevelopment"), I18n_TranslateWithFallback_E827B83("AppDevelopmentSubText")), I18n_TranslateWithFallback_E827B83("ReadMore")))), delay(() => singleton(FableProps_footer(I18n_TranslateWithFallback_E827B83("FooterHeader"), I18n_TranslateWithFallback_E827B83("ContactUs")))))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])))));
}

