import { FableProps_footer, Slick_partnerSlider, secondary, primary, third, FableProps_heroMini } from "../Props.js";
import { I18n_TranslateWithFallback_E827B83 } from "../fable_modules/Fable.I18Next.0.15.2/Fable.I18Next.fs.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.3.0.0/ElementBuilders.fs.js";
import tf_image from "../../public/png/tf_image.png";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { singleton, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import ts from "../../public/png/partner/ts.png";
import msu from "../../public/png/partner/msu.png";
import compositionalIT from "../../public/png/partner/compositionalIT.png";

export function view(model, dispatch) {
    let elems_5, elems_4, elms, elems_1, elems, elems_2;
    const children_1 = ofArray([FableProps_heroMini(I18n_TranslateWithFallback_E827B83("WhoAreWe")), createElement("div", createObj(Helpers_combineClasses("content", singleton((elems_5 = [createElement("section", createObj(Helpers_combineClasses("section", ofArray([["style", {
        backgroundColor: third,
    }], (elems_4 = [(elms = ofArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-4"], (elems_1 = [createElement("figure", createObj(Helpers_combineClasses("image", ofArray([["className", "is-5by3"], (elems = [createElement("img", {
        src: tf_image,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-8"], (elems_2 = [createElement("h2", {
        style: {
            color: primary,
        },
        children: "Tim Forkmann",
    }), createElement("h4", {
        style: {
            color: secondary,
        },
        children: I18n_TranslateWithFallback_E827B83("CEO"),
    }), createElement("p", {
        style: {
            color: "#FFFFFF",
        },
        children: I18n_TranslateWithFallback_E827B83("Tim"),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), createElement("h1", {
        style: {
            color: primary,
        },
        children: I18n_TranslateWithFallback_E827B83("Partner"),
    }), createElement("h2", {
        style: {
            color: secondary,
        },
        children: I18n_TranslateWithFallback_E827B83("PartnerText"),
    }), Slick_partnerSlider(ts, msu, compositionalIT)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))), FableProps_footer(I18n_TranslateWithFallback_E827B83("FooterHeader"), I18n_TranslateWithFallback_E827B83("ContactUs"))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])))))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_1)),
    });
}

