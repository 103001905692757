import { view as view_1 } from "./views/About.js";
import { view as view_2 } from "./views/Imprint.js";
import { view as view_3 } from "./views/Blogs.js";
import { view as view_4 } from "./views/Blog.js";
import { view as view_5 } from "./views/SaaS.js";
import { view as view_6 } from "./views/Contact.js";
import { view as view_7 } from "./views/AppDevelopment.js";
import { view as view_8 } from "./views/Consultancy.js";
import { view as view_9 } from "./views/EnergyDataConsultancy.js";
import { view as view_10 } from "./views/DigitalMeteringConsultancy.js";
import { view as view_11 } from "./views/EnergyEngineeringServices.js";
import { view as view_12 } from "./views/ProjectMethodology.js";
import { view as view_13 } from "./views/ElectroMobility.js";
import { view as view_14 } from "./views/Home.js";
import { I18n_TranslateWithFallback_E827B83 } from "./fable_modules/Fable.I18Next.0.15.2/Fable.I18Next.fs.js";
import { parseUrl, Page, getHref } from "./Router.js";
import { Language_get_Values, Language__get_Value, Language__get_GetLogo, Msg, NavigationLink } from "./Domain.js";
import { singleton as singleton_1, map, empty, cons, fold, ofArray } from "./fable_modules/fable-library.4.10.0/List.js";
import { slide, Style, Style_get_unwrap, toStyleObject, Style_get_toStyleName, Animation, Animation_get_importType, Dimension } from "./Feliz.ReactBurgerMenu.js";
import { printf, toText } from "./fable_modules/fable-library.4.10.0/String.js";
import { equals, createObj } from "./fable_modules/fable-library.4.10.0/Util.js";
import { SideMenuStyles_menuHeaderAvatar, SideMenuStyles_menuHeader, SideMenuStyles_overlay, SideMenuStyles_morphShape, SideMenuStyles_menuWrap, SideMenuStyles_burgerBars, SideMenuStyles_item, SideMenuStyles_itemList, SideMenuStyles_cross, SideMenuStyles_crossButton, SideMenuStyles_burgerButton, SideMenuStyles_menu } from "./Props.js";
import { map as map_1, tryFind, singleton, append, delay, toList } from "./fable_modules/fable-library.4.10.0/Seq.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { Interop_reactApi as Interop_reactApi_1 } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.3.0.0/ElementBuilders.fs.js";
import logo_long_croped from "../public/png/logo_long_croped.png";
import { RouterModule_router } from "./fable_modules/Feliz.Router.4.0.0/Router.fs.js";

export function contentPart(model, dispatch) {
    const matchValue = model.CurrentPage;
    switch (matchValue.tag) {
        case 1:
            return view_1(model, dispatch);
        case 2:
            return view_2(model, dispatch);
        case 3:
            return view_3(model, dispatch);
        case 4:
            return view_4(model, dispatch);
        case 5:
            return view_5(model, dispatch);
        case 6:
            return view_6(model, dispatch);
        case 7:
            return view_7(model, dispatch);
        case 8:
            return view_8(model, dispatch);
        case 9:
            return view_9(model, dispatch);
        case 10:
            return view_10(model, dispatch);
        case 11:
            return view_11(model, dispatch);
        case 12:
            return view_12(model, dispatch);
        case 13:
            return view_13(model, dispatch);
        default:
            return view_14(model, dispatch);
    }
}

export function menuLinks() {
    return ofArray([new NavigationLink(I18n_TranslateWithFallback_E827B83("Home"), getHref(new Page(0, [])), "fas fa-home"), new NavigationLink(I18n_TranslateWithFallback_E827B83("About"), getHref(new Page(1, [])), "fas fa-info"), new NavigationLink(I18n_TranslateWithFallback_E827B83("Imprint"), getHref(new Page(2, [])), "fas fa-certificate"), new NavigationLink(I18n_TranslateWithFallback_E827B83("AppDevelopment"), getHref(new Page(7, [])), "fas fa-plus-circle"), new NavigationLink(I18n_TranslateWithFallback_E827B83("ElectroMobility"), getHref(new Page(13, [])), "fas fa-charging-station"), new NavigationLink(I18n_TranslateWithFallback_E827B83("Consultancy"), getHref(new Page(8, [])), "fas fa-plus-circle"), new NavigationLink(I18n_TranslateWithFallback_E827B83("EnergyDataConsultancy"), getHref(new Page(9, [])), "fas fa-bolt"), new NavigationLink(I18n_TranslateWithFallback_E827B83("DigitalMeteringConsultancy"), getHref(new Page(10, [])), "fas fa-hdd"), new NavigationLink(I18n_TranslateWithFallback_E827B83("EnergyEngineeringServices"), getHref(new Page(11, [])), "fas fa-hdd"), new NavigationLink(I18n_TranslateWithFallback_E827B83("Contact"), getHref(new Page(6, [])), "fas fa-id-card"), new NavigationLink("Blogs", getHref(new Page(3, [])), "fas fa-tasks"), new NavigationLink(I18n_TranslateWithFallback_E827B83("ProjectMethodology"), getHref(new Page(12, [])), "fab fa-blogger-b")]);
}

export function burgerMenu(model, dispatch) {
    let width, e;
    const props_5 = ofArray([(width = (new Dimension(2, [280])), (width.tag === 1) ? ["width", toText(printf("%i%%"))(width.fields[0])] : ((width.tag === 2) ? ["width", toText(printf("%ipx"))(width.fields[0])] : ["width", width.fields[0]])), ["outerContainerId", "outer-container"], ["pageWrapId", "page-wrap"], ["isOpen", model.MenuOpen], ["animation", Animation_get_importType()(new Animation(7, []))], ["onOpen", (_arg) => {
        dispatch(new Msg(4, []));
    }], ["onClose", (_arg_1) => {
        dispatch(new Msg(4, []));
    }], ["styles", createObj(fold((acc, style) => cons([Style_get_toStyleName()(style), toStyleObject(Style_get_unwrap()(style))], acc), empty(), ofArray([new Style(0, [SideMenuStyles_menu]), new Style(1, [SideMenuStyles_burgerButton]), new Style(4, [SideMenuStyles_crossButton]), new Style(5, [SideMenuStyles_cross]), new Style(8, [SideMenuStyles_itemList]), new Style(9, [SideMenuStyles_item]), new Style(2, [SideMenuStyles_burgerBars]), new Style(6, [SideMenuStyles_menuWrap]), new Style(7, [SideMenuStyles_morphShape]), new Style(10, [SideMenuStyles_overlay])])))], (e = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(ofArray([["style", createObj(SideMenuStyles_menuHeader)], ["style", {
            textAlign: "center",
        }], (elems = [createElement("div", {
            style: createObj(SideMenuStyles_menuHeaderAvatar),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => map((link) => {
            let elems_1;
            return createElement("a", createObj(ofArray([["style", {
                textAlign: "left",
            }], ["onClick", (_arg_2) => {
                dispatch(new Msg(8, [link.Href]));
            }], (elems_1 = [createElement("i", {
                style: {
                    marginRight: 10,
                },
                className: link.Icon,
            }), createElement("div", {
                children: link.Label,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
        }, menuLinks())));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(e))])]);
    let importType;
    const _arg_3 = tryFind((arg_2) => ("animation" === arg_2[0]), props_5);
    importType = ((_arg_3 == null) ? slide : _arg_3[1]);
    return Interop_reactApi_1.createElement(importType, createObj(props_5));
}

export function navbar(model, dispatch) {
    let elems_11, elms, elems_2, elems_10, elms_1, items, elems_1, elems, elms_3, elems_8, elms_2, elems_7;
    const item = (t, p) => {
        const isActive = equals(model.CurrentPage, p) ? ofArray([["className", "is-active"], ["className", "has-background-primary"]]) : empty();
        return createElement("a", createObj(Helpers_combineClasses("navbar-item", toList(delay(() => append(isActive, delay(() => append(singleton(["className", "has-text-white"]), delay(() => append(singleton(["children", t]), delay(() => singleton(["href", getHref(p)]))))))))))));
    };
    return createElement("nav", createObj(Helpers_combineClasses("navbar", ofArray([["className", "is-fixed-top"], ["style", {
        backgroundColor: "rgb(47,69,92)",
    }], (elems_11 = [(elms = singleton_1(createElement("a", createObj(Helpers_combineClasses("navbar-item", ofArray([["href", getHref(new Page(0, []))], ["style", {
        paddingBottom: 0,
        paddingTop: 0,
    }], (elems_2 = [createElement("img", {
        src: logo_long_croped,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))))), createElement("div", {
        className: "navbar-brand",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), createElement("div", createObj(Helpers_combineClasses("navbar-menu", ofArray([["id", "nav-menu"], (elems_10 = [(elms_1 = ofArray([item(I18n_TranslateWithFallback_E827B83("Home"), new Page(0, [])), item(I18n_TranslateWithFallback_E827B83("About"), new Page(1, [])), item(I18n_TranslateWithFallback_E827B83("Contact"), new Page(6, [])), (items = ofArray([item(I18n_TranslateWithFallback_E827B83("AppDevelopment"), new Page(7, [])), item(I18n_TranslateWithFallback_E827B83("ElectroMobility"), new Page(13, [])), item(I18n_TranslateWithFallback_E827B83("Consultancy"), new Page(8, [])), item(I18n_TranslateWithFallback_E827B83("EnergyEngineeringServices"), new Page(11, [])), item(I18n_TranslateWithFallback_E827B83("EnergyDataConsultancy"), new Page(9, [])), item(I18n_TranslateWithFallback_E827B83("DigitalMeteringConsultancy"), new Page(10, []))]), createElement("div", createObj(Helpers_combineClasses("navbar-item", ofArray([["className", "has-dropdown"], ["className", "is-hoverable"], (elems_1 = [createElement("a", createObj(Helpers_combineClasses("navbar-link", ofArray([["className", "has-text-white"], ["children", I18n_TranslateWithFallback_E827B83("Services")], ["href", getHref(new Page(10, []))]])))), createElement("div", createObj(Helpers_combineClasses("navbar-dropdown", ofArray([["className", "has-background-primary"], (elems = toList(delay(() => items)), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))))), item(I18n_TranslateWithFallback_E827B83("ProjectMethodology"), new Page(12, [])), item("Blog", new Page(3, []))]), createElement("div", {
        className: "navbar-start",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (elms_3 = singleton_1(createElement("a", createObj(Helpers_combineClasses("navbar-item", ofArray([["className", "has-dropdown"], ["className", "is-hoverable"], (elems_8 = [(elms_2 = singleton_1(createElement("img", {
        style: {
            marginRight: 5,
            height: 30,
        },
        src: Language__get_GetLogo(model.CurrentLanguage),
        alt: Language__get_Value(model.CurrentLanguage),
    })), createElement("a", {
        className: "navbar-item",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    })), createElement("div", createObj(Helpers_combineClasses("navbar-dropdown", ofArray([["className", "is-right"], (elems_7 = toList(delay(() => map_1((language) => {
        let elems_6;
        return createElement("a", createObj(Helpers_combineClasses("navbar-item", ofArray([["onClick", (_arg) => {
            dispatch(new Msg(2, [language]));
        }], (elems_6 = [createElement("img", {
            style: {
                marginRight: 5,
                height: 30,
            },
            src: Language__get_GetLogo(language),
            alt: Language__get_Value(language),
        }), createElement("p", {
            children: [Language__get_Value(language)],
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))));
    }, Language_get_Values()))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))))), createElement("div", {
        className: "navbar-end",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])]))));
}

export function viewNavbar(model, dispatch) {
    const elms = singleton_1(navbar(model, dispatch));
    return createElement("nav", {
        className: "navbar",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function view(model, dispatch) {
    let elems_1, elems;
    const render = createElement("div", createObj(singleton_1((elems_1 = [createElement("div", createObj(ofArray([["className", "is-hidden-desktop"], (elems = [burgerMenu(model, dispatch)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), viewNavbar(model, dispatch), contentPart(model, dispatch)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]))));
    return RouterModule_router({
        onUrlChanged: (arg_1) => {
            dispatch(new Msg(0, [parseUrl(arg_1)]));
        },
        application: react.createElement(react.Fragment, {}, render),
    });
}

