import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.3.0.0/ElementBuilders.fs.js";
import { singleton, cons, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { Msg } from "../Domain.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { FableProps_footer, FableProps_pWithIconWhite, third, FableProps_heroMini } from "../Props.js";
import { I18n_TranslateWithFallback_E827B83 } from "../fable_modules/Fable.I18Next.0.15.2/Fable.I18Next.fs.js";
import { Fa_IconOption } from "../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import workspot from "../../public/png/workspot.png";

export function customLabel(text) {
    return createElement("label", createObj(Helpers_combineClasses("label", ofArray([["style", {
        color: "#FFFFFF",
    }], ["children", text]]))));
}

export function form(lableFullName, lableEmailAddress, lablePhoneNumber, lableMessage, lableSubmit, howCanWeHelp, contactInfo, dispatch) {
    let elms_1, elms, elms_2, elems_2, elms_3, elems_4, elms_4, elems_6, elems_9, elms_5;
    const children_6 = ofArray([(elms_1 = ofArray([customLabel(lableFullName), (elms = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["defaultValue", contactInfo.FullName], ["onChange", (ev) => {
        dispatch(new Msg(9, [ev.target.value]));
    }], ["placeholder", "Tim Forkmann"]])))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (elms_2 = ofArray([customLabel(lableEmailAddress), createElement("div", createObj(Helpers_combineClasses("control", singleton((elems_2 = [createElement("input", createObj(cons(["type", "email"], Helpers_combineClasses("input", ofArray([["defaultValue", contactInfo.EmailAddress], ["className", "is-white"], ["onChange", (ev_1) => {
        dispatch(new Msg(12, [ev_1.target.value]));
    }], ["placeholder", "example@gmail.com"]])))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])))))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    })), (elms_3 = ofArray([customLabel(lablePhoneNumber), createElement("div", createObj(Helpers_combineClasses("control", singleton((elems_4 = [createElement("input", createObj(cons(["type", "tel"], Helpers_combineClasses("input", ofArray([["value", contactInfo.PhoneNumber], ["className", "is-white"], ["onChange", (ev_2) => {
        dispatch(new Msg(10, [ev_2.target.value]));
    }], ["placeholder", "+1 123-456-7890"]])))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])))))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    })), (elms_4 = ofArray([customLabel(lableMessage), createElement("div", createObj(Helpers_combineClasses("control", singleton((elems_6 = [createElement("textarea", createObj(Helpers_combineClasses("textarea", ofArray([["placeholder", howCanWeHelp], ["value", contactInfo.Message], ["className", "is-white"], ["onChange", (ev_3) => {
        dispatch(new Msg(11, [ev_3.target.value]));
    }]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])))))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    })), createElement("div", createObj(Helpers_combineClasses("field", ofArray([["className", "is-grouped"], ["className", "is-grouped-centered"], (elems_9 = [(elms_5 = singleton(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-primary"], ["onClick", (_arg) => {
        dispatch(new Msg(13, [contactInfo]));
    }], ["className", "is-white"], ["children", lableSubmit]]))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))))]);
    return createElement("form", {
        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
    });
}

export function view(model, dispatch) {
    let elems_9, elms, elems_1, elems, elms_4, elems_5, elms_2, elms_1, elems_7, elms_3;
    const elms_5 = ofArray([FableProps_heroMini(I18n_TranslateWithFallback_E827B83("ContactText")), createElement("section", createObj(Helpers_combineClasses("section", ofArray([["style", {
        backgroundColor: third,
    }], (elems_9 = [(elms = ofArray([createElement("div", createObj(Helpers_combineClasses("column", singleton(["className", "is-3"])))), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-6"], (elems_1 = [createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-primary"], ["className", "is-fullwidth"], ["style", {
        textDecoration: "none",
    }], (elems = [createElement("a", {
        className: "is-white",
        style: {
            textDecoration: "none",
            color: "#FFFFFF",
        },
        children: I18n_TranslateWithFallback_E827B83("BookConsultation"),
        target: "_blank",
        href: "https://meetings-eu1.hubspot.com/tim-forkmann",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), createElement("div", createObj(Helpers_combineClasses("column", singleton(["className", "is-3"]))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (elms_4 = ofArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-6"], (elems_5 = [form(I18n_TranslateWithFallback_E827B83("ContactFullName"), I18n_TranslateWithFallback_E827B83("ContactEmail"), I18n_TranslateWithFallback_E827B83("PhoneNumber"), I18n_TranslateWithFallback_E827B83("Message"), I18n_TranslateWithFallback_E827B83("ContactUs"), I18n_TranslateWithFallback_E827B83("HowCanWeHelp"), model.ContactInfo, dispatch), createElement("br", {}), (elms_2 = singleton((elms_1 = ofArray([createElement("h3", {
        style: {
            color: "#FFFFFF",
        },
        children: I18n_TranslateWithFallback_E827B83("ContactPhone"),
    }), FableProps_pWithIconWhite(new Fa_IconOption(11, ["fas fa-phone"]), I18n_TranslateWithFallback_E827B83("PhoneTim")), createElement("h3", {
        style: {
            color: "#FFFFFF",
        },
        children: I18n_TranslateWithFallback_E827B83("ContactEmailMessage"),
    }), FableProps_pWithIconWhite(new Fa_IconOption(11, ["fas fa-paper-plane"]), I18n_TranslateWithFallback_E827B83("EmailTim"))]), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-6"], ["style", {
        textAlign: "center",
    }], (elems_7 = [(elms_3 = singleton(createElement("img", {
        src: workspot,
    })), createElement("figure", {
        className: "image",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))), FableProps_footer(I18n_TranslateWithFallback_E827B83("FooterHeader"), I18n_TranslateWithFallback_E827B83("ContactUs"))]);
    return createElement("div", {
        className: "content",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    });
}

