{
    "translation": {
        "About": "About",
        "AboutText": "We are a young IT consulting company, here to help you with the development and maintenance of your website. We offer a variety of products and services that will make your ideas a reality. We also offer a number of components that can help you develop your website.",
        "Address": "Address",
        "AppDevelopment": "App Development",
        "AppDevelopmentHeader": "We develop apps for you",
        "AppDevelopmentReactNative": "Cross-Platform App Development with React Native and Expo",
        "AppDevelopmentReactNativeText": "We develop apps for you using React Native and Expo for iOS and Android",
        "AppDevelopmentText1": "Cutting-edge app development",
        "AppDevelopmentText2": "Internationalization with i18n",
        "AppDevelopmentText3": "Interactive diagrams with React Native Skia",
        "AppDevelopmentText4": "White label apps",
        "AppDevelopmentText5": "High-performance mobile app development for iOS and Android",
        "AppDevelopmentText6": "Push notifications",
        "Azure": "We are experienced Azure Cloud Solutions architects.",
        "AzureHeader": "Azure Cloud Solutions",
        "Basic": "The basics are all included",
        "Blog": "Latest updates, news and views",
        "BookConsultation": "Arrange a consultation appointment now",
        "BusinessAreas": "Business areas",
        "CEO": "Lead Software Consultant / CEO",
        "ElectroMobility" : "Electro mobility",
        "CollectorText": "Use our Collector to digitize your locations",
        "CompanyCityAndPostalCode": "K1S 5A2, Ottawa, ON",
        "CompanyCountry": "Canada",
        "CompanyName": "DataBreeze Inc.",
        "CompanyStreet": "30 Marco Lane",
        "CompetenceDigitalMetering": "Competence in Digital Metering",
        "CompetenceDigitalMeteringText": "We would be happy to support you in capturing digital meter values. We know our way around the IoT!",
        "Consultancy": "IT consultancy",
        "ConsultancySubText": "We help you to implement sustainable and modern IT solutions. We implement data validation processes to ensure your data quality meets regulatory requirements. We are experienced in developing customized reporting systems that send recurring reports to official institutes.",
        "ConsultingHeader": "We offer following IT consultancy",
        "Contact": "Contact",
        "ContactCEO": "CEO: Tim Forkmann",
        "ContactEmail": "Email address",
        "ContactEmailMessage": "or drop us an e-mail",
        "ContactFullName": "Full name",
        "ContactPhone": "We are happy to hear from you. Give as a call:",
        "ContactText": "What can we do for you?",
        "ContactUs": "Contact us",
        "ContinuousImprovement": "Continuous improvement and support",
        "ContinuousImprovement1": "system monitoring",
        "ContinuousImprovement2": "software updates",
        "ContinuousImprovement3": "security patches",
        "ContinuousImprovement4": "bug fixes",
        "ContinuousImprovement5": "performance improvements",
        "ContinuousImprovement6": "system testing to ensure critical data integrity",
        "CorePhilosophy": "Core Philosophy",
        "Correctness": "Correctness",
        "CostEffectiveSolutions": "Cost-Effective Solutions",
        "Creativity": "Creativity",
        "CrossPlatform": "Cross-Platform",
        "CustomerFocused": "Customer focused",
        "CustomizedFirmware": "Custom firmware development",
        "CustomizedFirmwareText": "We can adapt the device firmware specifically to your needs",
        "CustomizedRegulatoryReports": "Custom data reports for regulatory compliance",
        "CustomizedRegulatoryReportsText": "We help you to create reports for regulatory compliance.",
        "DataAnalysis": "Data analysis and adjustment",
        "DataAnalysisText": "We find missing or erroneous values in large databases and make corrections where needed.",
        "DataExport": "Data export and connectivity",
        "DataPipelines": "Data pipelines configuration",
        "DataPipelinesText": "We optimize your data stream and implement modern data pipelines for you.",
        "DataProtocol": "Variety of data transfer protocols",
        "DataProtocolText": "Modbus, GPIO, S7, MBus",
        "DataQuality": "Data quality and process optimization",
        "DataQualityText": "There are major errors and gaps in data, especially in existing IT infrastructures, which lead to inconsistent reports. We implement data validation and replacement value creation processes for you in order to be able to create plausible reports at any time.",
        "DataSolutions": "Data solutions",
        "DataSolutionsText": "We help you with your problems",
        "DataVisualization": "Data visualization & analysis",
        "DeviceManagement": "Device management",
        "DeviceManagementText": "DataBreeze helps you configure and organize your devices in a modern and performant way",
        "DigitalMetering": "Digital metering (IoT)",
        "DigitalMeteringConsultancy": "Digital metering consultancy",
        "DigitalMeteringConsultancySubText": "We are familiar with modern IoT technologies such as LoRaWan and Narrowband-IoT. Digital meter registration is our specialty.",
        "DigitalMeteringHeader": "IoT all the way",
        "DigitalTwin": "Digital twin",
        "DigitalTwinText": "We map your data in a digital twin",
        "DomainDrivenDesign": "Domain driven design",
        "EfficientDevelopmentProcess": "Efficient Development Process",
        "ElectricMobility": "Electric mobility",
        "EmailTim": "tim.forkmann@databreeze.ca",
        "EnergyControlling": "Energy controlling",
        "EnergyControllingText": "We set up a effective and sustainable energy controlling for you",
        "EnergyDataAnalysis": "Energy Data Analysis",
        "EnergyDataConsultancy": "Energy data consultancy",
        "EnergyDataConsultancySubText": "We are experienced in energy data, energy management and modern energy solutions.",
        "EnergyDataConsultancyText": "We know our way around energy data and are happy to help",
        "EnergyDataHeader": "We know our way around energy data and are happy to help",
        "EnergyDataManagement": "Energy data management according to ISO 50001",
        "EnergyDataManagementText": "We create energy reports for you in accordance with ISO 50001",
        "EnergyEfficiencyAnalysis": "Energy efficiency analysis",
        "EnergyEfficiencyAnalysisText": "With the help of energy data, we identify your energy optimization potential",
        "EnergyEngineeringServices": "Energy engineering services",
        "EnergyEngineeringServicesHeader": "We are would be happy to help you with your energy engineering project",
        "EnergyEngineeringServicesSubText": "We are experienced in project management, project development and engineering services as well as  PID drawings and industrial automation technology.",
        "EnergyEngineeringServicesText": "We offer engineering services for renewable energy systems as well as conventional energy systems.",
        "EnergyMeters": "Energy meters",
        "EnergyMixCalculation": "Energy mix calculation",
        "EnergyMixCalculationText": "We determine your energy mix from large amounts of data. Calculating the primary energy factor, proportion of regenerative energies, etc. is no problem for us",
        "EngineeringServices": "Engineering services",
        "EngineeringServicesText": "PID drawings and technical drawings (AutoCAD)",
        "Expertise": "This is how we can help you",
        "ExternalAndInternalReporting": "External and internal reporting",
        "ExternalAndInternalReportingText": "We would be happy to set up a sustainable, high-performance reporting system for you.",
        "FollowUs": "Follow us",
        "FooterHeader": "Start your next project with us",
        "FSharpText": "F# and Functional Programming",
        "GoLive": "Polishing to production ready and go live",
        "GoLive1": "polish the prototypes",
        "GoLive2": "show the final product",
        "GoLive3": "again gather customer feedback",
        "GoLive4": "finally deliver the product",
        "HighPerformanceApps": "High-Performance Apps",
        "Home": "Home",
        "HowCanWeHelp": "How can we help?",
        "Imprint": "Imprint",
        "IndustrialAutomation": "Industrial automation",
        "IndustrialAutomationText": "PLC programming (S7, Allen-Bradley etc.)",
        "IoTCloudSolutions": "IoT cloud solutions (data storage, IoT hub, data pipelines)",
        "IoTCloudSolutionsText": "We implement IoT cloud solutions for dynamic data processing of your digital meter data",
        "IoTSolutions": "IoT Solutions",
        "KnowHowEnergySector": "Know-how in the energy sector",
        "AppDevelopmentSubText": "We develop cross-platform React Native Expo apps for you",
        "KnowHowEnergySectorText": "We have in-depth legal and technical knowledge in the energy sector and would be happy to advise you on new technologies",
        "KnowledgeInTheFollowingAreas": "Knowledge in the following areas",
        "LandingButton": "Let us help you with your data",
        "LandingHeader": "A breath of fresh air for your data needs",
        "LandingText": "Cutting-edge IoT solutions, data pipelines, energy data consulting and software solutions",
        "Language": "Language",
        "LanguageChanged": "Language changed to English",
        "LoadProfileProcessing": "Load profile processing methods",
        "LoadProfileProcessingText": "Our aggregator is able to create dynamic load profiles. The creation of substitute values and the aggregation of the values is contained in the aggregator.",
        "Maintainability": "Maintainability",
        "ManualMeasures": "Manual meter recording",
        "ManualMeasuresText": "Grid for dynamic input of data",
        "MarketCommunication": "Market communication (EDIFACT)",
        "MarketCommunicationText": "We use EDIFACT for automated technical processing of master and movement data and thus uniform and future-oriented market communication",
        "Message": "Message",
        "MessageSent": "Message successfully sent",
        "Modularity": "Modularity",
        "NET": "We mainly develop .NET software",
        "OpenSourceInfused": "Open source infused",
        "Partner": "Partner",
        "PartnerText": "Here are some of our amazing partners",
        "Performant": "Performant",
        "PhoneNumber": "Phone number",
        "PhoneTim": "+1-613-316-0947",
        "PreciseDataVisualization": "Precise data visualization & tailor-made benchmarking",
        "PreciseDataVisualizationText": "We present your data clearly and offer state of the art benchmarking tools.",
        "ProcessEngineering": "Process engineering",
        "ProcessEngineeringText": "We are specialized in the areas of process engineering and industrial automation.",
        "ProjectManagement": "Project management",
        "ProjectManagementText": "We offer services from feasibility studies to project management and implementation.",
        "ProjectMethodology": "Project methodology",
        "ProjectStartup1": "define the problem",
        "ProjectStartup2": "define the requirements",
        "ProjectStartup3": "define the infrastructure and security",
        "ProjectStartup4": "define the technology best practices",
        "ProjectStartupConsultation": "Project startup consultation",
        "Prototyping1": "create early prototypes or poc",
        "Prototyping2": "show core functionality",
        "Prototyping3": "gather customer feedback",
        "PrototypingAndScripting": "Prototyping and scripting",
        "ReadMore": "Read more here",
        "RegulatoryReports": "RegulatoryReports",
        "Reliability": "Reliability",
        "RenewableEnergyConsultancy": "Renewable energy consultancy",
        "RenewableEnergyConsultancyText": "Project feasibility studies for biogas plants, photovoltaics, heat pumps etc.",
        "Reusability": "Reusability",
        "SaaS": {
            "CustomReporting": "Custom Reporting",
            "DeviceManagement": "Device Management",
            "LoadProfileManagement": "Load Profile Management",
            "MarketCommunication": "Market Communication",
            "ReportManagement": "Report Management"
        },
        "SaaSAggregatorText1": "Accurate data aggregation and data replacement. We run plausibility and reliability test for you. The Aggregator will list all the missing and replaced values.",
        "SaaSAggregatorText2": "Reliable and performant data aggregation",
        "SaaSAggregatorText3": "Data Aggregation",
        "SaaSAggregatorText4": "Flag and list missing values",
        "SaaSAggregatorText5": "Data Replacement",
        "SaaSAggregatorText6": "Plausibility and reliability tests",
        "SaaSAggregatorText7": "Performante background aggregation",
        "SaaSAnalyzerText1": "With our Analyzer you are able to analyze your data in a fresh and modern way. Our benchmarking tool helps you to show how your system is performing. The Analyzer calculates your renewable energy ratio, CO2 balance and primary energy factor for you.",
        "SaaSAnalyzerText2": "Quickly benchmark your efficiency",
        "SaaSAnalyzerText3": "Benchmarking Tool",
        "SaaSAnalyzerText4": "Energy efficiency calculation",
        "SaaSAnalyzerText5": "Calculate renewable energy content",
        "SaaSAnalyzerText6": "CO2 balance calculation",
        "SaaSAnalyzerText7": "Calculate the primary energy factor",
        "SaaSCollectorText1": "Easy collect your metering data using a modern Device management. You can upload your data (CSV, Excel etc.), use a data input grid, use a meter app or install you Collector on side which collects all your data continuously and fully automated.",
        "SaaSCollectorText2": "Connect your data in just a breeze",
        "SaaSCollectorText3": "Intuitive DeviceManagement",
        "SaaSCollectorText4": "Customized Firmware development",
        "SaaSCollectorText5": "Manual Data Input - Data Grid",
        "SaaSCollectorText6": "MeteringApp",
        "SaaSHeader": "Choose one of our packages",
        "SaaSPriceBasic": "90 CAD/plant/month",
        "SaaSPriceEnterprise": "150 CAD/plant/month",
        "SaaSPricePro": "120 CAD/plant/month",
        "SaaSReportingText1": "Our reporting tool gives you an overview about all your recurring reports. Reports can be customized for internal reporting, or you can use our regulatory compliance reports for your external reporting.",
        "SaaSReportingText2": "High performance and precise reports",
        "SaaSReportingText3": "High performance",
        "SaaSReportingText4": "Highest data quality reporting",
        "SaaSReportingText5": "Regulatory compliance",
        "SaaSReportingText6": "Easy report configuration",
        "SaaSReportingText7": "Report overview",
        "SaaSReportingText8": "Reports can be triggered",
        "SAFEStackSolutions": "SAFE Stack Solutions",
        "Security": "Security",
        "Services": "Services",
        "SqlServer": "If you have an huge legacy sql server infrastructure we have no problem to handle it",
        "Tim": "Tim Forkmann graduated from TU Berlin with a Master of Science in Renewable Energy Engineering. He has worked for over 10 years in the energy sector. He began his career at Danpower GmbH as a Project Engineer. In 2015, he became the Energy Data Manager at Danpower and then Head of Energy Data & Digitalization in 2020. In this role, he was responsible for leading a team of three people. With his passion for F# and his in-depth knowledge of energy data, he decided to found DataBreeze in 2021.",
        "TO": "Technical Officer",
        "TrustedTechnologies": "Trusted Technologies",
        "TrustedTechnologiesText": "We rely on Functional Programming, Open Source and Microsoft products",
        "UnlimitedMeterPoints": "Unlimited Meter Points",
        "UnlimitedStorage": "Unlimited Storage",
        "UserEngagement": "User Engagement",
        "WaterMetering": "Water metering",
        "WhoAreWe": "Who are we",
        "WhyFsharp": "Why F#",
        "WhyFsharpText": "Fast time to market, low bug ratio, cheap maintenance, and strong .NET platform make it a top enterprise-ready development language"
    }
}
