import { FableProps_footer, FableProps_iconTileWithTextDarkBackground, FableProps_secondaryH3, FableProps_primaryH1, third, FableProps_heroMini } from "../Props.js";
import { I18n_TranslateWithFallback_E827B83 } from "../fable_modules/Fable.I18Next.0.15.2/Fable.I18Next.fs.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.3.0.0/ElementBuilders.fs.js";
import appdevelopment from "../../public/png/appdevelopment.png";
import { ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import appdevelopment2 from "../../public/png/appdevelopment2.png";
import { Fa_ISize, Fa_IconOption } from "../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";

export function view(model, dispatch) {
    let elems_13, elems_5, elems_4, elms, elms_1, elems_3, elems_2, elems_12, elms_7, elms_2, elms_3, elms_4, elms_5, elms_6;
    const children_8 = ofArray([FableProps_heroMini(I18n_TranslateWithFallback_E827B83("AppDevelopment")), createElement("div", createObj(Helpers_combineClasses("content", ofArray([["style", {
        overflow: "auto",
    }], (elems_13 = [createElement("section", createObj(Helpers_combineClasses("section", ofArray([["style", {
        textAlign: "center",
        backgroundColor: third,
        color: "#FFFFFF",
    }], (elems_5 = [createElement("div", createObj(Helpers_combineClasses("columns", singleton((elems_4 = [(elms = singleton(createElement("img", {
        style: {
            height: 500,
        },
        src: appdevelopment,
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (elms_1 = singleton(createElement("img", {
        style: {
            height: 500,
        },
        src: appdevelopment2,
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["style", {
        textAlign: "left",
    }], (elems_3 = [FableProps_primaryH1(I18n_TranslateWithFallback_E827B83("AppDevelopmentReactNative")), createElement("p", {
        children: I18n_TranslateWithFallback_E827B83("AppDevelopmentReactNativeText"),
    }), FableProps_secondaryH3(I18n_TranslateWithFallback_E827B83("AppDevelopmentText1")), createElement("ul", createObj(ofArray([["style", {
        listStyleType: "square",
    }], (elems_2 = [createElement("li", {
        children: [I18n_TranslateWithFallback_E827B83("AppDevelopmentText2")],
    }), createElement("li", {
        children: [I18n_TranslateWithFallback_E827B83("AppDevelopmentText3")],
    }), createElement("li", {
        children: [I18n_TranslateWithFallback_E827B83("AppDevelopmentText4")],
    }), createElement("li", {
        children: [I18n_TranslateWithFallback_E827B83("AppDevelopmentText5")],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))), createElement("section", createObj(Helpers_combineClasses("section", ofArray([["style", {
        backgroundColor: third,
        textAlign: "center",
    }], (elems_12 = [createElement("h1", createObj(Helpers_combineClasses("title", ofArray([["className", "has-text-primary"], ["children", I18n_TranslateWithFallback_E827B83("CorePhilosophy")], ["style", {
        textAlign: "center",
    }]])))), (elms_7 = ofArray([(elms_2 = singleton(FableProps_iconTileWithTextDarkBackground(new Fa_IconOption(11, ["fas fa-mobile-alt"]), new Fa_ISize(4, []), I18n_TranslateWithFallback_E827B83("CrossPlatform"))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    })), (elms_3 = singleton(FableProps_iconTileWithTextDarkBackground(new Fa_IconOption(11, ["fas fa-tachometer-alt"]), new Fa_ISize(4, []), I18n_TranslateWithFallback_E827B83("HighPerformanceApps"))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    })), (elms_4 = singleton(FableProps_iconTileWithTextDarkBackground(new Fa_IconOption(11, ["fas fa-toolbox"]), new Fa_ISize(4, []), I18n_TranslateWithFallback_E827B83("EfficientDevelopmentProcess"))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    })), (elms_5 = singleton(FableProps_iconTileWithTextDarkBackground(new Fa_IconOption(11, ["fas fa-envelope"]), new Fa_ISize(4, []), I18n_TranslateWithFallback_E827B83("UserEngagement"))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    })), (elms_6 = singleton(FableProps_iconTileWithTextDarkBackground(new Fa_IconOption(11, ["fas fa-money-bill-alt"]), new Fa_ISize(4, []), I18n_TranslateWithFallback_E827B83("CostEffectiveSolutions"))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
    }))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))), FableProps_footer(I18n_TranslateWithFallback_E827B83("FooterHeader"), I18n_TranslateWithFallback_E827B83("ContactUs"))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])]))))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_8)),
    });
}

