import { reveal as reveal_1, fallDown as fallDown_1, scaleRotate as scaleRotate_1, scaleDown as scaleDown_1, pushRotate as pushRotate_1, push as push_1, elastic as elastic_1, stack as stack_1, slide as slide_1, bubble as bubble_1 } from "react-burger-menu";
import { Union } from "./fable_modules/fable-library.4.10.0/Types.js";
import { list_type, class_type, int32_type, union_type } from "./fable_modules/fable-library.4.10.0/Reflection.js";
import { createObj } from "./fable_modules/fable-library.4.10.0/Util.js";
import { toArray } from "./fable_modules/fable-library.4.10.0/List.js";

export const bubble = bubble_1;

export const slide = slide_1;

export const stack = stack_1;

export const elastic = elastic_1;

export const push = push_1;

export const pushRotate = pushRotate_1;

export const scaleDown = scaleDown_1;

export const scaleRotate = scaleRotate_1;

export const fallDown = fallDown_1;

export const reveal = reveal_1;

export class Animation extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Elastic", "Slide", "Stack", "Push", "PushRotate", "ScaleDown", "ScaleRotate", "FallDown", "Reveal"];
    }
}

export function Animation_$reflection() {
    return union_type("Feliz.ReactBurgerMenu.Animation", [], Animation, () => [[], [], [], [], [], [], [], [], []]);
}

export function Animation_get_importType() {
    return (_arg) => ((_arg.tag === 1) ? slide : ((_arg.tag === 2) ? stack : ((_arg.tag === 3) ? push : ((_arg.tag === 4) ? pushRotate : ((_arg.tag === 5) ? scaleDown : ((_arg.tag === 6) ? scaleRotate : ((_arg.tag === 7) ? fallDown : ((_arg.tag === 8) ? reveal : elastic))))))));
}

export class Dimension extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Int", "Percent", "Pixel"];
    }
}

export function Dimension_$reflection() {
    return union_type("Feliz.ReactBurgerMenu.Dimension", [], Dimension, () => [[["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]]]);
}

export class MenuPosition extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Left", "Right"];
    }
}

export function MenuPosition_$reflection() {
    return union_type("Feliz.ReactBurgerMenu.MenuPosition", [], MenuPosition, () => [[], []]);
}

export class Style extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Menu", "BurgerButton", "BurgerBars", "BurgerBarsHover", "CrossButton", "Cross", "MenuWrap", "MorphShape", "ItemList", "Item", "Overlay"];
    }
}

export function Style_$reflection() {
    return union_type("Feliz.ReactBurgerMenu.Style", [], Style, () => [[["Item", list_type(class_type("Feliz.IStyleAttribute"))]], [["Item", list_type(class_type("Feliz.IStyleAttribute"))]], [["Item", list_type(class_type("Feliz.IStyleAttribute"))]], [["Item", list_type(class_type("Feliz.IStyleAttribute"))]], [["Item", list_type(class_type("Feliz.IStyleAttribute"))]], [["Item", list_type(class_type("Feliz.IStyleAttribute"))]], [["Item", list_type(class_type("Feliz.IStyleAttribute"))]], [["Item", list_type(class_type("Feliz.IStyleAttribute"))]], [["Item", list_type(class_type("Feliz.IStyleAttribute"))]], [["Item", list_type(class_type("Feliz.IStyleAttribute"))]], [["Item", list_type(class_type("Feliz.IStyleAttribute"))]]]);
}

export function Style_get_toStyleName() {
    return (_arg) => ((_arg.tag === 1) ? "bmBurgerButton" : ((_arg.tag === 2) ? "bmBurgerBars" : ((_arg.tag === 3) ? "bmBurgerBarsHover" : ((_arg.tag === 4) ? "bmCrossButton" : ((_arg.tag === 5) ? "bmCross" : ((_arg.tag === 6) ? "bmMenuWrap" : ((_arg.tag === 7) ? "bmMorphShape" : ((_arg.tag === 8) ? "bmItemList" : ((_arg.tag === 9) ? "bmItem" : ((_arg.tag === 10) ? "bmOverlay" : "bmMenu"))))))))));
}

export function Style_get_unwrap() {
    return (_arg) => ((_arg.tag === 1) ? _arg.fields[0] : ((_arg.tag === 2) ? _arg.fields[0] : ((_arg.tag === 3) ? _arg.fields[0] : ((_arg.tag === 4) ? _arg.fields[0] : ((_arg.tag === 5) ? _arg.fields[0] : ((_arg.tag === 6) ? _arg.fields[0] : ((_arg.tag === 7) ? _arg.fields[0] : ((_arg.tag === 8) ? _arg.fields[0] : ((_arg.tag === 9) ? _arg.fields[0] : ((_arg.tag === 10) ? _arg.fields[0] : _arg.fields[0]))))))))));
}

export function toStyleObject(styles) {
    return createObj(toArray(styles));
}

export class BurgerMenu {
    constructor() {
    }
}

export function BurgerMenu_$reflection() {
    return class_type("Feliz.ReactBurgerMenu.BurgerMenu", void 0, BurgerMenu);
}

export function BurgerMenu_customBurgerIcon_Z1EA77C2C(icon) {
    if (icon == null) {
        return ["customBurgerIcon", false];
    }
    else {
        return ["customBurgerIcon", icon];
    }
}

export function BurgerMenu_customCrossIcon_Z1EA77C2C(icon) {
    if (icon == null) {
        return ["customCrossIcon", false];
    }
    else {
        return ["customCrossIcon", icon];
    }
}

