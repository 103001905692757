import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.3.0.0/ElementBuilders.fs.js";
import { FableProps_footer, FableProps_tiles, FableProps_heroMini, FableProps_iconTileWithText, primary, FableProps_iconTileWithTextDarkBackground, third } from "../Props.js";
import { singleton, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { Fa_ISize, Fa_IconOption } from "../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { I18n_TranslateWithFallback_E827B83 } from "../fable_modules/Fable.I18Next.0.15.2/Fable.I18Next.fs.js";
import pi from "../../public/png/pi.png";
import smart_metering from "../../public/png/smart_metering.png";

export function sectionThreeDigitalMetering(readMoreStr, iotSolutions) {
    let elems_4, elms_3, elms, elms_1, elms_2;
    return createElement("section", createObj(Helpers_combineClasses("section", ofArray([["style", {
        backgroundColor: third,
        color: "#FFFFFF",
        textAlign: "center",
    }], (elems_4 = [createElement("h1", createObj(Helpers_combineClasses("title", ofArray([["className", "has-text-primary"], ["children", iotSolutions], ["style", {}]])))), (elms_3 = ofArray([(elms = singleton(FableProps_iconTileWithTextDarkBackground(new Fa_IconOption(11, ["fas fa-sync"]), new Fa_ISize(4, []), "MQTT")), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (elms_1 = singleton(FableProps_iconTileWithTextDarkBackground(new Fa_IconOption(11, ["fas fa-wifi"]), new Fa_ISize(4, []), "LoRaWan")), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (elms_2 = singleton(FableProps_iconTileWithTextDarkBackground(new Fa_IconOption(11, ["fas fa-signal"]), new Fa_ISize(4, []), "NB-IoT")), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))));
}

export function sectionKnowledgeInDigitalMetering(knowledge) {
    let elems_4, elms_3, elms, elms_1, elms_2;
    return createElement("section", createObj(Helpers_combineClasses("section", ofArray([["style", {
        backgroundColor: "#FFFFFF",
        color: primary,
        textAlign: "center",
    }], (elems_4 = [createElement("h1", createObj(Helpers_combineClasses("title", ofArray([["className", "has-text-primary"], ["children", knowledge], ["style", {}]])))), (elms_3 = ofArray([(elms = singleton(FableProps_iconTileWithText(new Fa_IconOption(11, ["fas fa-water"]), new Fa_ISize(4, []), I18n_TranslateWithFallback_E827B83("WaterMetering"))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (elms_1 = singleton(FableProps_iconTileWithText(new Fa_IconOption(11, ["fas fa-tachometer-alt"]), new Fa_ISize(4, []), I18n_TranslateWithFallback_E827B83("EnergyMeters"))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (elms_2 = singleton(FableProps_iconTileWithText(new Fa_IconOption(11, ["fas fa-charging-station"]), new Fa_ISize(4, []), I18n_TranslateWithFallback_E827B83("ElectricMobility"))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))));
}

export function view(model, dispatch) {
    let elems_15, elems_7, elems, elms, elems_2, elems_1, elems_5, elems_4, elems_3, elems_14, elms_1, elems_10, elems_9, elems_8, elems_12, elems_11;
    const children_2 = ofArray([FableProps_heroMini(I18n_TranslateWithFallback_E827B83("DigitalMeteringHeader")), createElement("div", createObj(Helpers_combineClasses("content", ofArray([["style", {
        overflow: "auto",
    }], (elems_15 = [sectionKnowledgeInDigitalMetering(I18n_TranslateWithFallback_E827B83("KnowledgeInTheFollowingAreas")), createElement("section", createObj(Helpers_combineClasses("section", ofArray([["style", {
        backgroundColor: third,
        color: "#FFFFFF",
        textDecorationColor: "#0000FF",
    }], (elems_7 = [createElement("div", createObj(ofArray([["style", {
        textAlign: "center",
    }], (elems = [createElement("h1", {
        style: {
            color: "#FFFFFF",
        },
        children: I18n_TranslateWithFallback_E827B83("CompetenceDigitalMetering"),
    }), createElement("p", {
        children: [I18n_TranslateWithFallback_E827B83("CompetenceDigitalMeteringText")],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("hr", {}), (elms = ofArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-6"], (elems_2 = [createElement("figure", createObj(Helpers_combineClasses("image", singleton((elems_1 = [createElement("img", {
        src: pi,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-6"], (elems_5 = [createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-ancestor"], (elems_4 = [createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-parent"], ["className", "is-vertical"], (elems_3 = [FableProps_tiles(new Fa_IconOption(11, ["fas fa-microchip"]), I18n_TranslateWithFallback_E827B83("DigitalMetering"), I18n_TranslateWithFallback_E827B83("CollectorText"), true, void 0), FableProps_tiles(new Fa_IconOption(11, ["fas fa-chart-line"]), I18n_TranslateWithFallback_E827B83("DeviceManagement"), I18n_TranslateWithFallback_E827B83("DeviceManagementText"), true, void 0), FableProps_tiles(new Fa_IconOption(11, ["fas fa-industry"]), I18n_TranslateWithFallback_E827B83("DataProtocol"), I18n_TranslateWithFallback_E827B83("DataProtocolText"), true, void 0), FableProps_tiles(new Fa_IconOption(11, ["fas fa-code-branch"]), I18n_TranslateWithFallback_E827B83("CustomizedFirmware"), I18n_TranslateWithFallback_E827B83("CustomizedFirmwareText"), true, void 0)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))), createElement("section", createObj(Helpers_combineClasses("section", singleton((elems_14 = [(elms_1 = ofArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-6"], (elems_10 = [createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-ancestor"], (elems_9 = [createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-parent"], ["className", "is-vertical"], (elems_8 = [FableProps_tiles(new Fa_IconOption(11, ["fas fa-table"]), I18n_TranslateWithFallback_E827B83("ManualMeasures"), I18n_TranslateWithFallback_E827B83("ManualMeasuresText"), false, void 0), FableProps_tiles(new Fa_IconOption(11, ["fas fa-chart-line"]), I18n_TranslateWithFallback_E827B83("LoadProfileProcessing"), I18n_TranslateWithFallback_E827B83("LoadProfileProcessingText"), false, void 0), FableProps_tiles(new Fa_IconOption(11, ["fas fa-industry"]), I18n_TranslateWithFallback_E827B83("MarketCommunication"), I18n_TranslateWithFallback_E827B83("MarketCommunicationText"), false, void 0), FableProps_tiles(new Fa_IconOption(11, ["fas fa-hdd"]), I18n_TranslateWithFallback_E827B83("IoTCloudSolutions"), I18n_TranslateWithFallback_E827B83("IoTCloudSolutionsText"), false, void 0)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-6"], (elems_12 = [createElement("figure", createObj(Helpers_combineClasses("image", singleton((elems_11 = [createElement("img", {
        src: smart_metering,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])]))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))]))))), sectionThreeDigitalMetering(I18n_TranslateWithFallback_E827B83("ReadMore"), I18n_TranslateWithFallback_E827B83("IoTSolutions")), FableProps_footer(I18n_TranslateWithFallback_E827B83("FooterHeader"), I18n_TranslateWithFallback_E827B83("ContactUs"))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])]))))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    });
}

