import { Language__get_Key, Model, Msg, ModelModule_init } from "./Domain.js";
import { empty, length, isEmpty, ofArray, singleton } from "./fable_modules/fable-library.4.10.0/List.js";
import { singleton as singleton_1 } from "./fable_modules/fable-library.4.10.0/AsyncBuilder.js";
import { sleep } from "./fable_modules/fable-library.4.10.0/Async.js";
import { Remoting_buildProxy_64DC51C } from "./fable_modules/Fable.Remoting.Client.7.31.0/Remoting.fs.js";
import { RemotingModule_createApi, RemotingModule_withRouteBuilder, RemotingModule_withBinarySerialization } from "./fable_modules/Fable.Remoting.Client.7.31.0/Remoting.fs.js";
import { ContactInfo, IBlogApi_$reflection, IDataBreezeApi_$reflection, Route_builder } from "./Shared/Shared.js";
import { Cmd_batch, Cmd_OfPromise_either, Cmd_none } from "./fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { I18n_ChangeLanguage_Z721C83C5 } from "./fable_modules/Fable.I18Next.0.15.2/Fable.I18Next.fs.js";
import { Toaster_toastMessageSent, Toaster_toastLanguage } from "./Props.js";
import { Cmd_OfAsyncWith_either, Cmd_OfAsync_start, Cmd_OfAsyncWith_perform } from "./fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { printf, toConsole } from "./fable_modules/fable-library.4.10.0/String.js";
import { Cmd_ofEffect } from "./fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { RouterModule_nav } from "./fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { Page, getHref } from "./Router.js";
import { find } from "./fable_modules/fable-library.4.10.0/Array.js";

export function init() {
    return [ModelModule_init, singleton((dispatch) => {
        dispatch(new Msg(17, []));
    })];
}

function delay(msg) {
    return singleton_1.Delay(() => singleton_1.Bind(sleep(2000), () => singleton_1.Return(msg)));
}

export const dataBreezeApi = Remoting_buildProxy_64DC51C(RemotingModule_withBinarySerialization(RemotingModule_withRouteBuilder(Route_builder, RemotingModule_createApi())), IDataBreezeApi_$reflection());

export const blogApi = Remoting_buildProxy_64DC51C(RemotingModule_withBinarySerialization(RemotingModule_withRouteBuilder(Route_builder, RemotingModule_createApi())), IBlogApi_$reflection());

export function update(msg, currentModel) {
    let bind$0040, bind$0040_1, bind$0040_2, bind$0040_3, msg_7, blogContent;
    switch (msg.tag) {
        case 4:
            return [new Model(currentModel.CurrentPage, currentModel.ShowQuickView, currentModel.ShowLoader, !currentModel.MenuOpen, currentModel.CurrentPath, currentModel.SelectedBlogContent, currentModel.ContactInfo, currentModel.CurrentTab, currentModel.SaaSContent, currentModel.BlogContents, currentModel.CurrentLanguage, currentModel.Error), Cmd_none()];
        case 2: {
            const lang = msg.fields[0];
            return [currentModel, Cmd_OfPromise_either(I18n_ChangeLanguage_Z721C83C5, Language__get_Key(lang), () => (new Msg(3, [lang])), (Item) => (new Msg(5, [Item])))];
        }
        case 3:
            return [new Model(currentModel.CurrentPage, currentModel.ShowQuickView, currentModel.ShowLoader, currentModel.MenuOpen, currentModel.CurrentPath, currentModel.SelectedBlogContent, currentModel.ContactInfo, currentModel.CurrentTab, currentModel.SaaSContent, currentModel.BlogContents, msg.fields[0], currentModel.Error), Cmd_batch(ofArray([Toaster_toastLanguage(), singleton((dispatch_1) => {
                dispatch_1(new Msg(0, [currentModel.CurrentPage]));
            })]))];
        case 1:
            return [new Model(currentModel.CurrentPage, currentModel.ShowQuickView, !currentModel.ShowLoader, currentModel.MenuOpen, currentModel.CurrentPath, currentModel.SelectedBlogContent, currentModel.ContactInfo, currentModel.CurrentTab, currentModel.SaaSContent, currentModel.BlogContents, currentModel.CurrentLanguage, currentModel.Error), !currentModel.ShowLoader ? Cmd_OfAsyncWith_perform((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, delay, new Msg(1, []), (x) => x) : Cmd_none()];
        case 7: {
            const segments = msg.fields[0];
            return [new Model(currentModel.CurrentPage, currentModel.ShowQuickView, currentModel.ShowLoader, currentModel.MenuOpen, segments, currentModel.SelectedBlogContent, currentModel.ContactInfo, currentModel.CurrentTab, currentModel.SaaSContent, currentModel.BlogContents, currentModel.CurrentLanguage, currentModel.Error), isEmpty(currentModel.CurrentTab) ? ((length(segments) > 2) ? singleton((dispatch_2) => {
                dispatch_2(new Msg(6, [segments]));
            }) : Cmd_none()) : Cmd_none()];
        }
        case 6: {
            const tabs = msg.fields[0];
            if (isEmpty(tabs)) {
                return [new Model(currentModel.CurrentPage, currentModel.ShowQuickView, currentModel.ShowLoader, currentModel.MenuOpen, currentModel.CurrentPath, currentModel.SelectedBlogContent, currentModel.ContactInfo, empty(), currentModel.SaaSContent, currentModel.BlogContents, currentModel.CurrentLanguage, currentModel.Error), Cmd_none()];
            }
            else {
                return [new Model(currentModel.CurrentPage, currentModel.ShowQuickView, currentModel.ShowLoader, currentModel.MenuOpen, currentModel.CurrentPath, currentModel.SelectedBlogContent, currentModel.ContactInfo, tabs, currentModel.SaaSContent, currentModel.BlogContents, currentModel.CurrentLanguage, currentModel.Error), Cmd_none()];
            }
        }
        case 5: {
            const arg_1 = msg.fields[0].message;
            toConsole(printf("Error: %s"))(arg_1);
            return [currentModel, Cmd_none()];
        }
        case 8:
            return [new Model(currentModel.CurrentPage, currentModel.ShowQuickView, currentModel.ShowLoader, false, currentModel.CurrentPath, currentModel.SelectedBlogContent, currentModel.ContactInfo, currentModel.CurrentTab, currentModel.SaaSContent, currentModel.BlogContents, currentModel.CurrentLanguage, currentModel.Error), Cmd_ofEffect((_arg_1) => {
                RouterModule_nav(singleton(msg.fields[0]), 1, 1);
            })];
        case 9:
            return [new Model(currentModel.CurrentPage, currentModel.ShowQuickView, currentModel.ShowLoader, currentModel.MenuOpen, currentModel.CurrentPath, currentModel.SelectedBlogContent, (bind$0040 = currentModel.ContactInfo, new ContactInfo(msg.fields[0], bind$0040.PhoneNumber, bind$0040.SendDate, bind$0040.Message, bind$0040.EmailAddress)), currentModel.CurrentTab, currentModel.SaaSContent, currentModel.BlogContents, currentModel.CurrentLanguage, currentModel.Error), Cmd_none()];
        case 10:
            return [new Model(currentModel.CurrentPage, currentModel.ShowQuickView, currentModel.ShowLoader, currentModel.MenuOpen, currentModel.CurrentPath, currentModel.SelectedBlogContent, (bind$0040_1 = currentModel.ContactInfo, new ContactInfo(bind$0040_1.FullName, msg.fields[0], bind$0040_1.SendDate, bind$0040_1.Message, bind$0040_1.EmailAddress)), currentModel.CurrentTab, currentModel.SaaSContent, currentModel.BlogContents, currentModel.CurrentLanguage, currentModel.Error), Cmd_none()];
        case 11:
            return [new Model(currentModel.CurrentPage, currentModel.ShowQuickView, currentModel.ShowLoader, currentModel.MenuOpen, currentModel.CurrentPath, currentModel.SelectedBlogContent, (bind$0040_2 = currentModel.ContactInfo, new ContactInfo(bind$0040_2.FullName, bind$0040_2.PhoneNumber, bind$0040_2.SendDate, msg.fields[0], bind$0040_2.EmailAddress)), currentModel.CurrentTab, currentModel.SaaSContent, currentModel.BlogContents, currentModel.CurrentLanguage, currentModel.Error), Cmd_none()];
        case 12:
            return [new Model(currentModel.CurrentPage, currentModel.ShowQuickView, currentModel.ShowLoader, currentModel.MenuOpen, currentModel.CurrentPath, currentModel.SelectedBlogContent, (bind$0040_3 = currentModel.ContactInfo, new ContactInfo(bind$0040_3.FullName, bind$0040_3.PhoneNumber, bind$0040_3.SendDate, bind$0040_3.Message, msg.fields[0])), currentModel.CurrentTab, currentModel.SaaSContent, currentModel.BlogContents, currentModel.CurrentLanguage, currentModel.Error), Cmd_none()];
        case 13:
            return [currentModel, Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, dataBreezeApi.AddContactInfo, msg.fields[0], () => (new Msg(14, [void 0])), (Item_3) => (new Msg(15, [Item_3])))];
        case 14:
            return [currentModel, Toaster_toastMessageSent()];
        case 15:
            return [new Model(currentModel.CurrentPage, currentModel.ShowQuickView, currentModel.ShowLoader, currentModel.MenuOpen, currentModel.CurrentPath, currentModel.SelectedBlogContent, currentModel.ContactInfo, currentModel.CurrentTab, currentModel.SaaSContent, currentModel.BlogContents, currentModel.CurrentLanguage, msg.fields[0]), Cmd_none()];
        case 16:
            return [new Model(currentModel.CurrentPage, currentModel.ShowQuickView, currentModel.ShowLoader, currentModel.MenuOpen, currentModel.CurrentPath, currentModel.SelectedBlogContent, currentModel.ContactInfo, currentModel.CurrentTab, msg.fields[0], currentModel.BlogContents, currentModel.CurrentLanguage, currentModel.Error), Cmd_none()];
        case 17:
            return [currentModel, Cmd_OfAsyncWith_either((x_3) => {
                Cmd_OfAsync_start(x_3);
            }, blogApi.GetBlogs, void 0, (Item_4) => (new Msg(19, [Item_4])), (Item_5) => (new Msg(20, [Item_5])))];
        case 19:
            return [new Model(currentModel.CurrentPage, currentModel.ShowQuickView, currentModel.ShowLoader, currentModel.MenuOpen, currentModel.CurrentPath, currentModel.SelectedBlogContent, currentModel.ContactInfo, currentModel.CurrentTab, currentModel.SaaSContent, msg.fields[0], currentModel.CurrentLanguage, currentModel.Error), singleton((dispatch_3) => {
                dispatch_3(new Msg(0, [currentModel.CurrentPage]));
            })];
        case 20:
            return [new Model(currentModel.CurrentPage, currentModel.ShowQuickView, currentModel.ShowLoader, currentModel.MenuOpen, currentModel.CurrentPath, currentModel.SelectedBlogContent, currentModel.ContactInfo, currentModel.CurrentTab, currentModel.SaaSContent, currentModel.BlogContents, currentModel.CurrentLanguage, msg.fields[0]), Cmd_none()];
        case 18: {
            const blogEntry = msg.fields[0];
            return [new Model(currentModel.CurrentPage, currentModel.ShowQuickView, currentModel.ShowLoader, currentModel.MenuOpen, currentModel.CurrentPath, blogEntry, currentModel.ContactInfo, currentModel.CurrentTab, currentModel.SaaSContent, currentModel.BlogContents, currentModel.CurrentLanguage, currentModel.Error), (msg_7 = (new Msg(8, [getHref(new Page(4, [blogEntry.BlogId]))])), singleton((dispatch_4) => {
                dispatch_4(msg_7);
            }))];
        }
        default: {
            const p = msg.fields[0];
            return [new Model(p, currentModel.ShowQuickView, currentModel.ShowLoader, currentModel.MenuOpen, currentModel.CurrentPath, currentModel.SelectedBlogContent, currentModel.ContactInfo, currentModel.CurrentTab, currentModel.SaaSContent, currentModel.BlogContents, currentModel.CurrentLanguage, currentModel.Error), (p.tag === 4) ? ((currentModel.SelectedBlogContent != null) ? Cmd_none() : ((blogContent = find((b) => (b.BlogId === p.fields[0]), currentModel.BlogContents), singleton((dispatch) => {
                dispatch(new Msg(18, [blogContent]));
            })))) : Cmd_none()];
        }
    }
}

